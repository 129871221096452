import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CommentTemplate, EvaluationTarget, RubricTarget } from '../../types/types';
import { KEY_SELECTED_COMMENT } from '../../utils/constants';
import { setPageTitle, storageAvailable } from '../../utils/functions';
import {
  copyCommentIntoAssignmentEvalRubric,
  copyCommentIntoAssignmentRubric,
  copyCommentIntoReflectionEvalRubric,
  copyCommentIntoRubricTemplate,
  getUserCommentTemplates,
} from '../../utils/requests';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import QueryTable, { Column } from '../core/display/QueryTable/QueryTable';

interface Props {
  isCopy?: boolean;
}

function CommentLibrary({ isCopy = false }: Props): JSX.Element {
  useEffect(() => setPageTitle('Comment Library'), []);

  const { rubricTemplateId, assignmentId, target } = useParams() as {
    rubricTemplateId?: string;
    assignmentId?: string;
    target?: RubricTarget;
  };

  const [addedList, setAddedList] = useState<string[]>([]);

  const navigate = useNavigate();

  const onAdd = useCallback(
    (commentTemplate: CommentTemplate) => {
      if (rubricTemplateId) {
        copyCommentIntoRubricTemplate(rubricTemplateId, commentTemplate.comment.commentId, () => undefined);
      } else if (assignmentId && target) {
        if (target === 'SUBMISSION')
          copyCommentIntoAssignmentRubric(assignmentId, commentTemplate.comment.commentId, () => undefined);
        else if (target === 'REFLECT')
          copyCommentIntoReflectionEvalRubric(assignmentId, commentTemplate.comment.commentId, () => undefined);
        else
          copyCommentIntoAssignmentEvalRubric(
            assignmentId,
            target as EvaluationTarget,
            commentTemplate.comment.commentId,
            () => undefined,
          );
      }
      setAddedList((prevList) => [...prevList, commentTemplate.comment.commentId]);
    },
    [assignmentId, rubricTemplateId, target],
  );

  const handleCommentSelect = useCallback(
    (commentTemplate: CommentTemplate) => {
      if (storageAvailable('sessionStorage'))
        window.sessionStorage.setItem(KEY_SELECTED_COMMENT, JSON.stringify(commentTemplate.comment));
      const copyParam = isCopy ? '?copy=true' : '';
      navigate(`/rubrics/comment/${commentTemplate.comment.commentId}${copyParam}`);
    },
    [navigate, isCopy],
  );

  const columns = useMemo<Column<CommentTemplate>[]>(() => {
    const cols: Column<CommentTemplate>[] = [
      { Header: 'Comment Name', accessor: 'commentName', className: 'left-align' },
      {
        Header: 'Created By',
        accessor: 'user',
        className: 'left-align',
        notSortable: true,
        Cell: (commentTemplate: CommentTemplate) => commentTemplate.user?.name,
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (commentTemplate: CommentTemplate) => moment(commentTemplate.comment.createdAt).format('ll'),
      },
      { Header: 'Uses', accessor: 'uses', className: 'center-align', notSortable: true },
    ];

    if (isCopy)
      cols.push({
        Header: 'Add',
        accessor: 'commentId',
        Cell: function AddCell(commentTemplate: CommentTemplate) {
          if (addedList.includes(commentTemplate.comment.commentId)) return <Button disabled>Added</Button>;
          return (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onAdd(commentTemplate);
              }}
            >
              Add
            </Button>
          );
        },
        className: 'center-align',
        notSortable: true,
      });

    return cols;
  }, [isCopy, addedList, onAdd]);

  return (
    <>
      <h1 className="sr-only">Comment Prompts</h1>
      {isCopy ? (
        <Button id="copy-return-btn" onClick={() => navigate(-1)}>
          Return to Rubric
        </Button>
      ) : null}
      <QueryTable
        title="Comment Prompts"
        ctrlsInsert={
          <div className="col-2">
            <Button variant="low sm" href="/rubrics/library/new/comment" route>
              <Icon code="add" ariaHidden /> New Comment
            </Button>
          </div>
        }
        columns={columns}
        queryRequest={getUserCommentTemplates}
        onRowSelect={handleCommentSelect}
        classNames={{
          ctrlsClassName: 'ctrls-row',
          ctrlsWrapperClassName: 'col-2',
          tableClassName: 'home-body',
        }}
      />
    </>
  );
}

export default CommentLibrary;
