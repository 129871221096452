/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import moment from 'moment-timezone';
import momentLocale from 'moment/min/moment-with-locales';
import {
  Assignment,
  AssignmentProgressStatus,
  Comment,
  CommentData,
  CommentTable,
  DeepLink,
  EvaluationTarget,
  PhaseCode,
  Rating,
  User,
} from '../types/types';
import _ from 'lodash';
import { API_URL, NUM_NOTIFICATIONS_KEY } from './constants';
import store from '../store';
import { logError } from '../actions';
import { ErrorObj } from './requests';
import { Dispatch } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';

axios.defaults.withCredentials = true;

/**
 * Returns an error for error-throwing
 * @param {string} message Error message
 * @param {number} code Error code
 */
export const error = (message: string, code: number): Error => {
  return Object.assign(new Error(message), { code: code });
};

/**
 * Makes a simple GET request with Axios
 * @param {string} path The path of the request endpoint
 * @param {function(object): *} successCallback A callback that accepts the response object
 * @param {function(*): *} failureCallback A callback for a failed request
 * @param {string} type The response type
 */
export const requestGet = <T>(
  path: string,
  successCallback: (arg0: T, arg1: AxiosResponse<T>) => void,
  failureCallback: (arg0: AxiosError) => boolean | void,
  type?: ResponseType,
): void => {
  const config: AxiosRequestConfig = {
    responseType: type,
  };
  axios
    .get<T>(API_URL + path, config)
    .then((res) => {
      successCallback(res.data, res);
    })
    .catch((err) => {
      if (failureCallback(err) !== true) {
        console.error(err);
        (store.dispatch as Dispatch<PayloadAction<ErrorObj[]>>)(
          logError(new ErrorObj('get-generic-fail', 'Request Failed', err)),
        );
      }
    });
};

/**
 * Makes a simple POST request with Axios
 * @param {string} path The path of the request endpoint
 * @param {object} data The data for the POST request
 * @param {function(object): *} successCallback A callback that accepts the response object after a successful request
 * @param {function(*): *} failureCallback A callback for a failed request
 * @param {object} config Configuration for the request
 */
export const requestPost = <T>(
  path: string,
  data: any,
  successCallback: (arg0: T, arg1: AxiosResponse<T>) => void,
  failureCallback: (arg0: AxiosError) => boolean | void,
  config: AxiosRequestConfig = {},
): void => {
  axios
    .post(API_URL + path, data, config)
    .then((res) => {
      successCallback(res.data, res);
    })
    .catch((err) => {
      if (failureCallback(err) !== true) {
        console.error(err);
        (store.dispatch as Dispatch<PayloadAction<ErrorObj[]>>)(
          logError(new ErrorObj('post-generic-fail', 'Request Failed', err)),
        );
      }
    });
};

/**
 * Makes a simple DELETE request with Axios
 * @param {string} path The path of the request endpoint
 * @param {function(object): *} successCallback A callback that accepts the response object after a successful request
 * @param {function(*): *} failureCallback A callback for a failed request
 * @param {object} config Configuration for the request
 */
export const requestDelete = <T>(
  path: string,
  successCallback: (arg0: T, arg1: AxiosResponse<T>) => void,
  failureCallback: (arg0: AxiosError) => boolean | void,
  config: AxiosRequestConfig = {},
): void => {
  axios
    .delete(API_URL + path, config)
    .then((res) => {
      successCallback(res.data, res);
    })
    .catch((err) => {
      if (failureCallback(err) !== true) {
        console.error(err);
        (store.dispatch as Dispatch<PayloadAction<ErrorObj[]>>)(
          logError(new ErrorObj('delete-generic-fail', 'Request Failed', err)),
        );
      }
    });
};

/**
 * Makes a simple PUT request with Axios
 * @param {string} path The path of the request endpoint
 * @param {object} data The data for the POST request
 * @param {function(object): *} successCallback A callback that accepts the response object after a successful request
 * @param {function(*): *} failureCallback A callback for a failed request
 * @param {object} config Configuration for the request
 */
export const requestPut = <T>(
  path: string,
  data: any,
  successCallback: (arg0: T, arg1: AxiosResponse<T>) => void,
  failureCallback: (arg0: AxiosError) => boolean | void,
  config: AxiosRequestConfig = {},
): void => {
  axios
    .put(API_URL + path, data, config)
    .then((res) => {
      successCallback(res.data, res);
    })
    .catch((err) => {
      if (failureCallback(err) !== true) {
        console.error(err);
        (store.dispatch as Dispatch<PayloadAction<ErrorObj[]>>)(
          logError(new ErrorObj('put-generic-fail', 'Request Failed', err)),
        );
      }
    });
};

/**
 * Takes two class names and returns them conjoined
 * @param {string} class1 The class string to append to
 * @param {string} class2 The class string to append
 */
export const appendClass = (class1: string, class2: string): string => {
  if (class1 === '') return class2;
  if (class2 === '') return class1;
  return class1 + ' ' + class2;
};

/**
 * Parses comments into a table (indexable by comment ID),
 * and invokes callback on table as state
 * @param {array} commentData
 * @param {function(object): *} commentTableCb
 */
export const genCommentTable = (commentData: CommentData[], commentTableCb: (arg0: CommentTable) => void): void => {
  const commentTable = {} as CommentTable;
  commentData.forEach((comment) => {
    // If new comment ID, create new index for it
    if (commentTable[comment.commentId] == null) commentTable[comment.commentId] = {};

    // At that comment ID, store the comment string at the index of the comment
    commentTable[comment.commentId][comment.commentNumber] = {
      comment: comment.comment, // this hurts
      pinDrop: comment.pinDrop,
    };
  });

  commentTableCb(commentTable);
};

export const genReflectionCommentTable = (
  reflectionComment: { reflectionCommentId?: string; relectionId?: string; commentId: string; comment: string }[],
  ReflectionCommentTableCb: (arg0: CommentTable) => void,
): void => {
  const commentTable = {} as CommentTable;
  reflectionComment.forEach((reflectionComment) => {
    // If new comment ID, create new index for it
    if (commentTable[reflectionComment.commentId] == null) commentTable[reflectionComment.commentId] = {};

    // At that comment ID, store the comment string at the index of the comment
    commentTable[reflectionComment.commentId][0] = {
      comment: reflectionComment.comment, // this hurts
      pinDrop: null,
    };
  });
  ReflectionCommentTableCb(commentTable);
};

/**
 * Takes comment rubric data and rating rubric data and
 * gives a sorted rubric.
 * @param {array} commentData
 * @param {array} ratingData
 */
export const sortRubric = (commentData: Comment[], ratingData: Rating[]): (Comment | Rating)[] => {
  const sortedComments = commentData.sort((a, b) => (a.order > b.order ? 1 : -1));
  const sortedRatings = ratingData.sort((a, b) => (a.order > b.order ? 1 : -1));
  let i = 0;
  let j = 0;
  const sortedRubric = [];
  while (i < sortedComments.length || j < sortedRatings.length) {
    const commentOrder = i < sortedComments.length ? sortedComments[i].order : Number.MAX_SAFE_INTEGER;
    const ratingOrder = j < sortedRatings.length ? sortedRatings[j].order : Number.MAX_SAFE_INTEGER;
    if (commentOrder < ratingOrder) {
      sortedRubric.push(sortedComments[i]);
      i++;
    } else {
      sortedRubric.push(sortedRatings[j]);
      j++;
    }
  }
  return sortedRubric;
};

/**
 * Detects whether a click event was outside of the given element(s)
 * @param {object} event Click event
 * @param {array} notelem An array of elements to check against
 * @returns True if click is outside given elements, false otherwise
 */
export const outsideClick = (event: MouseEvent, notelem: Element[]): boolean => {
  if (!notelem) return false;
  const targetEl = event.target as Element;
  let clickedOut = true;
  const len = notelem.length;
  for (let i = 0; i < len; i++) {
    if (notelem[i])
      if (targetEl === notelem[i] || notelem[i].contains(targetEl)) {
        clickedOut = false;
      }
  }
  if (clickedOut) return true;
  else return false;
};

/**
 * Determines whether the string is a valid JSON
 * @param {string} str The string to validate
 * @returns true if the string is a valid JSON; false otherwise
 */
export const isJSON = (str: string): boolean => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

/**
 * Determines whether the file name denotes a PDF filetype
 * @param {string} fileName
 */
export const isPDF = (fileName: string): boolean => {
  if (!fileName) return false;
  // Regular Expression for extracting the file extension
  const regex = /(?:\.([^.]+))?$/;
  const fileExtension = (fileName.match(regex) || [])[0] ?? '';
  const pdfString = '.pdf';
  // Make a case-insensitive comparison
  return (fileExtension ?? '').localeCompare(pdfString, undefined, {
    sensitivity: 'accent',
  }) === 0
    ? true
    : false;
};

/**
 * Copies the provided text to the clipboard
 * @param {string} text
 */
export const copyTextToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text);
};

export const formDataToObject = (formData: FormData): any => {
  const object: any = {};
  for (const pair of formData.entries()) {
    object[pair[0]] = pair[1];
  }
  return object;
};

export const formDataToObjectParsed = (formData: FormData): any => {
  const object: any = {};
  for (const pair of formData.entries()) {
    object[pair[0]] = typeof pair[1] === 'string' ? parseString(pair[1]) : pair[1];
  }
  return object;
};

const parseString = (str: string): string | number | boolean => {
  const num = _.toNumber(str);
  if (!_.isNaN(num)) return num;
  const low = str.toLowerCase();
  if (low === 'true') return true;
  if (low === 'false') return false;
  return str;
};

export const getPropsFromQuery = (query: URLSearchParams): { [index: string]: string | boolean | number } | any => {
  const props: { [index: string]: string | boolean | number } = {};
  for (const pair of query.entries()) {
    props[pair[0]] = pair[1];
    if (pair[1] === 'true') props[pair[0]] = true;
    if (pair[1] === 'false') props[pair[0]] = false;
    if (!Number.isNaN(Number(pair[1]))) props[pair[0]] = Number(pair[1]);
  }

  return props;
};

export const formatDate = (date: string): string => {
  return moment.utc(date).local().format('YYYY-MM-DD @ HH:mm');
};

export const getTimezoneList = (): string[] => {
  const popularTimezones = [
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'America/New_York',
    'America/Phoenix',
    'Europe/Amsterdam',
    'Europe/Berlin',
    'Europe/London',
  ];

  const allTimezones = moment.tz.names();

  popularTimezones.forEach((tz) => {
    const i = allTimezones.indexOf(tz);
    allTimezones.splice(i, 1);
  });

  return popularTimezones.concat(allTimezones);
};

export const mod = (n: number, m: number): number => {
  return ((n % m) + m) % m;
};

export const changeNumberInputWithBounds = (
  newValue: string,
  min: number,
  max: number,
  callback: React.Dispatch<React.SetStateAction<number>> | ((arg0: number) => void),
): void => {
  const intValue = parseInt(newValue);
  if (intValue >= min && intValue <= max) callback(intValue);
  else if (isNaN(intValue)) callback(min);
};

export const getAssignmentTypeText = (assignment: Assignment): string => {
  if (assignment.groupsEnabled && !assignment.peerEvaluationOnly) return 'Group Submission';
  if (assignment.instructorUpload) return 'Instructor Upload';
  if (assignment.peerEvaluationOnly) return 'Team Member Evaluation Only';
  return 'Peer Assessment';
};

export const regexMultiTest = (regexes: RegExp[], string: string): boolean => {
  return regexes.some((regex) => regex.test(string));
};

/**
 * Source: https://stackoverflow.com/a/14919494
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = false, dp = 1): string => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const getNextDeadline = (assignment: Assignment): string => {
  const currentMoment = moment();

  let deadline = '';
  if (assignment.progressStats) {
    const { submissionPhase, reviewPhase, feedbackPhase, evalPhase } = assignment.progressStats;
    const { submissionDeadline, reviewDeadline, feedbackDeadline, peerEvaluationDeadline, asyncEndDeadline } =
      assignment;
    if (asyncEndDeadline) {
      deadline = asyncEndDeadline;
    } else {
      if (submissionPhase && moment(submissionDeadline).isAfter(currentMoment)) deadline = submissionDeadline ?? '';
      else if (reviewPhase && moment(reviewDeadline).isAfter(currentMoment)) deadline = reviewDeadline ?? '';
      else if (feedbackPhase && moment(feedbackDeadline).isAfter(currentMoment)) deadline = feedbackDeadline ?? '';
      else if (evalPhase && moment(peerEvaluationDeadline).isAfter(currentMoment))
        deadline = peerEvaluationDeadline ?? '';
    }
  }

  return deadline;
};

export const getPhaseTimeLeft = (assignment: Assignment): string[] => {
  return getTimeRemainingForDate(getNextDeadline(assignment));
};

export const getTimeRemainingForDate = (date: string): string[] => {
  if (date === '') return [];

  const currentMoment = moment();
  const deadlineMoment = moment(date);
  const days = deadlineMoment.diff(currentMoment, 'days');
  deadlineMoment.subtract(days, 'days');
  const hours = deadlineMoment.diff(currentMoment, 'hours');
  deadlineMoment.subtract(hours, 'hours');
  const minutes = deadlineMoment.diff(currentMoment, 'minutes');

  let remainingArray: string[] = [];
  if (days > 0) remainingArray.push(`${days} day${days === 1 ? '' : 's'}`);
  if (hours > 0) remainingArray.push(`${hours} hr${hours === 1 ? '' : 's'}`);
  if (minutes > 0) remainingArray.push(`${minutes} min${minutes === 1 ? '' : 's'}`);
  if (remainingArray.length < 1) remainingArray.push('Less than a minute');
  return remainingArray;
};

export const handleKeySelect = (e: React.KeyboardEvent, callback: (e: React.KeyboardEvent) => void) => {
  if (e.key === ' ' || e.key === 'Enter') callback(e);
};

export const handleEnterKey = (e: React.KeyboardEvent, callback: (e: React.KeyboardEvent) => void) => {
  if (e.key === 'Enter') callback(e);
};

export const getEvalTargetFormatted = (target: EvaluationTarget): string => {
  switch (target) {
    case 'GROUP':
      return 'Group';
    case 'GROUP_LEADER':
      return 'Leader';
    case 'INSTRUCTOR':
      return 'Instructor';
    case 'MEMBER':
      return 'Member';
  }
};

export const getEvalTargetFormattedFull = (target: EvaluationTarget): string => {
  switch (target) {
    case 'GROUP':
      return 'Whole Group';
    case 'GROUP_LEADER':
      return 'Group Leader';
    case 'INSTRUCTOR':
      return 'Instructor';
    case 'MEMBER':
      return 'Group Member';
  }
};

export type PhaseStatus = {
  groupFormation: boolean;
  submission: boolean;
  review: boolean;
  feedback: boolean;
  evaluate: boolean;
  reflection: boolean;
};

type PhaseStatusDependentSettings = {
  instructorUpload: boolean;
  instructorGradedOnly: boolean;
  feedbackEnabled: boolean;
  peerEvaluationOnly: boolean;
  peerEvaluationEnabled: boolean;
  reflectionEnabled: boolean;
  groupFormationOnly: boolean;
};

export const getPhaseStatus = (settings?: PhaseStatusDependentSettings): PhaseStatus => {
  if (settings)
    return {
      groupFormation: settings.groupFormationOnly,
      submission: !settings.instructorUpload && !settings.peerEvaluationOnly && !settings.groupFormationOnly,
      review: !settings.instructorGradedOnly && !settings.peerEvaluationOnly && !settings.groupFormationOnly,
      feedback:
        settings.feedbackEnabled &&
        !settings.instructorGradedOnly &&
        !settings.peerEvaluationOnly &&
        !settings.groupFormationOnly,
      evaluate: settings.peerEvaluationEnabled,
      reflection: settings.reflectionEnabled,
    };
  return {
    groupFormation: false,
    submission: false,
    review: false,
    feedback: false,
    evaluate: false,
    reflection: false,
  };
};

export const getPhaseName = (code: PhaseCode): string => {
  switch (code) {
    case 'submit':
      return 'Submission';
    case 'review':
      return 'Review';
    case 'feedback':
      return 'Feedback';
    case 'evaluate':
      return 'Team Member Evaluation';
    case 'reflection':
      return 'Reflection';
    case 'groupFormation':
      return 'Group Formation';
    case 'async':
      return 'Async';
  }
};

export const lightenDarkenColor = (col: string, amt: number): string => {
  let usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const formatDollar = (price: number): string => `$${(price / 100).toFixed(2)}`;

export const getFocusableElements = (parent: Element | Document = document) => {
  return parent.querySelectorAll('button, [href], input, select, textarea, [tabindex="0"], [tabindex="-1"]');
};

export const focusNthElement = (parent: Element | Document = document, n: number) => {
  let focusableElem = getFocusableElements(parent).item(n);

  if ((focusableElem as HTMLInputElement).disabled === true) focusNthElement(parent, n + 1);

  // If element is a radio button, focus the one that's currently checked
  if (focusableElem.tagName === 'INPUT' && focusableElem.getAttribute('type') === 'radio') {
    const name = focusableElem.getAttribute('name');
    focusableElem = document.querySelector(`input[name="${name}"]:checked`) ?? focusableElem;
  }

  (focusableElem as HTMLElement)?.focus();
};

export const focusFirstElement = (parent: Element | Document = document) => focusNthElement(parent, 0);

export const focusSiteContent = (cb: () => void = () => undefined) => {
  focusFirstElement(document.getElementById('site-content') ?? undefined);
  cb();
};

export const setPageTitle = (title: string) => {
  // First, announce page title to assistive technologies
  const statusElem = document.getElementById('page-announcer');
  if (statusElem) {
    statusElem.innerText = title;
  }

  const numNotifications = storageAvailable('sessionStorage')
    ? window.sessionStorage.getItem(NUM_NOTIFICATIONS_KEY)
    : null;
  const notifPrefix = numNotifications && numNotifications !== '0' ? `(${numNotifications}) ` : '';

  document.title = notifPrefix + `${title} - Peerceptiv`;
  return () => {
    document.title = notifPrefix + `Peerceptiv`;
  };
};

export const displayNavbar = (user: User) => !(user.userId === '' || !user.activeAccount || user.forcePasswordChange);

export const isHeightReflow = () => window.innerHeight <= 490;

export const choosePlural = (num: number) => (num !== 1 ? 's' : '');

/**
 *
 * @param timestamp UTC timestamp
 * @returns Time difference represented by a string
 */
export const timeDiff = (timestamp: string): string => {
  const inputTime = moment.utc(timestamp);
  const currTime = moment.utc();
  const yearDiff = Math.abs(inputTime.diff(currTime, 'years'));
  const monthDiff = Math.abs(inputTime.diff(currTime, 'months'));
  const weekDiff = Math.abs(inputTime.diff(currTime, 'weeks'));
  const dayDiff = Math.abs(inputTime.diff(currTime, 'days'));
  const hourDiff = Math.abs(inputTime.diff(currTime, 'hours'));
  const minDiff = Math.abs(inputTime.diff(currTime, 'minutes'));
  const secDiff = Math.abs(inputTime.diff(currTime, 'seconds'));
  const diffs = [
    { diff: yearDiff, unit: 'y' },
    { diff: monthDiff, unit: 'mo' },
    { diff: weekDiff, unit: 'w' },
    { diff: dayDiff, unit: 'd' },
    { diff: hourDiff, unit: 'h' },
    { diff: minDiff, unit: 'min' },
    { diff: secDiff, unit: 's' },
  ];
  const topDiff = diffs.find((elem) => elem.diff > 0);
  if (topDiff) return `${topDiff.diff}${topDiff.unit}`;
  return 'now';
};

export const stringArrayIncludes = (arr: string[], str: string): boolean => arr.toString().includes(str);

// https://stackoverflow.com/a/17886301
export const escapeRegExp = (stringToGoIntoTheRegex: string) =>
  stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

export const deepLinkCallback = (deepLink: DeepLink) => {
  if (deepLink.deepLinkingJwt && deepLink.deepLinkingReturnUrl) {
    document.body.insertAdjacentHTML(
      'beforeend',
      `<form method="post" id="deep-link-form" action="${deepLink.deepLinkingReturnUrl}"><input type="hidden" value="${deepLink.deepLinkingJwt}" name="JWT"></form>`,
    );
    (document.getElementById('deep-link-form') as HTMLFormElement | null)?.submit();
  }
};

export const convertUTCDateToLocalDate = (date: string | null) => {
  if (date == null) {
    return null;
  }
  const localTimeInUTC = moment.utc(date).toDate();
  const localTime = moment(localTimeInUTC).format('L LT z');
  return localTime;
};

// https://www.mediacollege.com/internet/javascript/text/count-words.html
export const countWords = (str: string) => {
  return str.split(' ').filter(function (n) {
    return n !== '';
  }).length;
};

export const getPhaseColorFromAssignment = (assignment: Assignment) => {
  if (assignment.status === 'UNPUBLISHED') return '#e5e5e5';
  if (assignment.status === 'COMPLETE') return '#38b934';
  if (assignment.asyncEnabled) {
    return '#395061';
  } else if (assignment.progressStats) {
    const { submissionPhase, reviewPhase, feedbackPhase, evalPhase } = assignment.progressStats;
    if (submissionPhase) return '#7878f1';
    if (reviewPhase) return '#e676e3';
    if (feedbackPhase) return '#e4c445';
    if (evalPhase) return '#55c92d';
  }
  return '#ffffff';
};

export const getCurrentPhaseForUser = (assignment: Assignment, userProgress: AssignmentProgressStatus): PhaseCode => {
  const { asyncEnabled } = assignment;
  if (assignment.progressStats) {
    const { submissionPhase, reviewPhase, feedbackPhase, evalPhase } = assignment.progressStats;
    const { submissionPriority, reviewPriority, feedbackPriority, peerEvalPriority } = userProgress;
    const isSubmissionPhase = asyncEnabled ? submissionPriority === true : submissionPhase === true;
    const isReviewPhase = asyncEnabled ? reviewPriority === true : reviewPhase === true;
    const isFeedbackPhase = asyncEnabled ? feedbackPriority === true : feedbackPhase === true;
    const isEvaluationPhase = asyncEnabled ? peerEvalPriority === true : evalPhase === true;
    if (isSubmissionPhase && userProgress.showSubmission) return 'submit';
    if (isReviewPhase && userProgress.showReviewing) return 'review';
    if (isFeedbackPhase && userProgress.showFeedback) return 'feedback';
    if (isEvaluationPhase && userProgress.showEvaluations) return 'evaluate';
  }
  return 'async';
};

export const storageAvailable = (type: keyof (Window & typeof globalThis)) => {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

export const removeAllChildren = (elem: Element) => {
  while (elem.firstChild) {
    elem.removeChild(elem.lastChild as ChildNode);
  }
};

export const safeRender = (renderCb: () => React.ReactNode): React.ReactNode => {
  let node = React.createElement(React.Fragment) as React.ReactNode;

  try {
    node = renderCb();
  } catch (err) {
    node = React.createElement(React.Fragment, {}, 'Error');
  }

  return node;
};

/**
 * Required to use with customInvalidMessage. Call in an onChange handler.
 */
export const updateValidity = (e: React.FormEvent<HTMLInputElement>) => {
  const inputEl = e.target as HTMLInputElement;
  if (inputEl.checkValidity()) {
    inputEl.setCustomValidity('');
  }
};

export const customInvalidMessage = (messageMapping: Partial<Record<keyof ValidityState, string>>) => {
  return (e: React.FormEvent<HTMLInputElement>) => {
    const inputEl = e.target as HTMLInputElement;
    const validityState = inputEl.validity;
    inputEl.setCustomValidity('');
    for (const [type, message] of Object.entries(messageMapping)) {
      if (validityState[type as keyof ValidityState]) inputEl.setCustomValidity(message);
    }
  };
};

export const presetInvalidMessage = (fieldName: string) => {
  return customInvalidMessage({ valueMissing: `Please fill out the '${fieldName}' field` });
};

export const conditionallyPlural = (value: number, unit: string) => {
  return `${value} ${unit}${value !== 1 ? 's' : ''}`;
};

export const getColorFromScore = (score: number) => {
  if (score < 60)
    // F range
    return '#eb7373';
  else if (score < 80)
    // C-D range
    return '#e9e335';
  // A-B range
  else return '#58cf7c';
};

export const getBackgroundColorFromScore = (score: number) => {
  if (score < 60)
    // F range
    return '#eb7373';
  else if (score < 80)
    // C-D range
    return '#ffff87';
  // A-B range
  else return '#58cf7c';
};

export const getDefaultEvalTargetForAssignment = (assignment: Assignment): EvaluationTarget => {
  if (assignment.memberEvalEnabled) return 'MEMBER';
  if (assignment.groupEvalEnabled) return 'GROUP';
  if (assignment.instructorEvalEnabled) return 'INSTRUCTOR';
  if (assignment.leaderEvalEnabled) return 'GROUP_LEADER';
  return 'MEMBER';
};

export const capitalizeWords = (input: string): string => {
  return input
    .toLowerCase()
    .split(' ')
    .map((word) => (word.length > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : ''))
    .join(' ');
};

export const applyLanguageSettings = () => {
  type NavigatorLanguage = { userLanguage?: string };
  let localeLang = navigator.language || (navigator as NavigatorLanguage).userLanguage;
  momentLocale.locale(localeLang);
  moment.localeData(localeLang);
  type Locale = { _config: moment.LocaleSpecification };
  moment.defineLocale(localeLang ?? '', (momentLocale.localeData() as unknown as Locale)._config);
};
