import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DailyTaskEvents, InstructorCourse, User } from '../../types/types';
import { KEY_SELECTED_USER } from '../../utils/constants';
import { formatDate, storageAvailable, timeDiff } from '../../utils/functions';
import { getInstructorCourse, getUserDailyActivity } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import TabList from '../core/layout/TabList/TabList';
import ActivityGraph from '../dashboard/teacher/ActivityGraph';
import UserDetailsPerformanceTab from './UserDetailsPerformanceTab';
import Table from '../core/display/Table/Table';
import { ColumnDef } from '@tanstack/react-table';

function UserDetailsPage(): JSX.Element {
  const { userId } = useParams() as { userId?: string };

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (storageAvailable('sessionStorage'))
      setUser(JSON.parse(window.sessionStorage.getItem(KEY_SELECTED_USER) ?? `${null}`));
  }, []);

  if (user && user.userId === userId) {
    return (
      <div className="page" id="user-details-page">
        <h1 className="sr-only">User Details</h1>
        <div className="tabs-wrapper">
          <TabList
            label="Roster Menu"
            tabs={
              <>
                <TabList.Tab id="details" controls="details-tab">
                  Details
                </TabList.Tab>
                <TabList.Tab id="activity" controls="activity-tab">
                  Activity
                </TabList.Tab>
                <TabList.Tab id="performance" controls="performance-tab">
                  Performance
                </TabList.Tab>
              </>
            }
          >
            <TabList.TabPanel id="details-tab" labeledBy="details">
              <DetailsTab user={user} />
            </TabList.TabPanel>
            <TabList.TabPanel id="activity-tab" labeledBy="activity">
              <ActivityTab user={user} />
            </TabList.TabPanel>
            <TabList.TabPanel id="performance-tab" labeledBy="performance">
              <UserDetailsPerformanceTab user={user} />
            </TabList.TabPanel>
          </TabList>
        </div>
      </div>
    );
  }
  return <LoadingSpinner />;
}

export interface UserDetailsTabProps {
  user: User;
}

function DetailsTab({ user }: UserDetailsTabProps): JSX.Element {
  return (
    <div className="details-card panel-sm ">
      <table>
        <tr>
          <th>Last Active</th>
          <td>{user.lastLoginTime ? `${timeDiff(user.lastLoginTime ?? '')} ago` : 'Never'}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </td>
        </tr>
        <tr>
          <th>Enrolled Courses</th>
          <td>x</td>
        </tr>
        <tr>
          <th>Joined</th>
          <td>{moment(user.createdAt).format('lll')}</td>
        </tr>
      </table>
    </div>
  );
}

function ActivityTab({ user }: UserDetailsTabProps): JSX.Element {
  const [dailyTaskEvents, setDailyTaskEvents] = useState<DailyTaskEvents[] | null>(null);
  const [instructorCourses, setInstructorCourses] = useState<InstructorCourse[] | null>(null);

  useEffect(() => {
    if (user.role === 'TEACHER') {
      getInstructorCourse(user.userId, setInstructorCourses);
    } else {
      getUserDailyActivity(user.userId, setDailyTaskEvents);
    }
  }, [user]);

  if (dailyTaskEvents || instructorCourses) {
    if (user.role === 'TEACHER' && instructorCourses) {
      return <InstructorCourseTable instructorCourses={instructorCourses} />;
    } else {
      if (dailyTaskEvents)
        return (
          <div id="activity-card">
            <h2>Activity</h2>
            <ActivityGraph height={150} dailyTaskEvents={dailyTaskEvents} dataDisplay="TASKS" />
          </div>
        );
      return <LoadingSpinner />;
    }
  } else {
    return <LoadingSpinner />;
  }
}

export interface InstructorCourseTableProps {
  instructorCourses: InstructorCourse[];
}

type TableData = {
  courseName: string;
  createdAt?: string;
  roster?: number;
  type?: string;
  assignmentCount: number;
  peerEvaluationAssginmentCount: number;
  instructorGradedOnlyAssignmentCount: number;
};

function InstructorCourseTable({ instructorCourses }: InstructorCourseTableProps): JSX.Element {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);

  useEffect(() => {
    if (instructorCourses) {
      const columns: ColumnDef<TableData>[] = [
        { header: 'Course Name', accessorKey: 'courseName', meta: {  className: 'left-align'} },
      ];
      columns.push({ header: 'Created At', accessorKey: 'createdAt' });
      columns.push({ header: 'Roster', accessorKey: 'roster' });
      columns.push({ header: 'Type', accessorKey: 'type' });
      columns.push({ header: 'Assignment', accessorKey: 'assignmentCount' });
      columns.push({ header: 'Peer Evaluation Only Assignment', accessorKey: 'peerEvaluationAssginmentCount' });
      columns.push({ header: 'Instructor Graded Only Assignment', accessorKey: 'instructorGradedOnlyAssignmentCount' });
      setTableColumns(columns)
    }
  },[instructorCourses]);

  useEffect(() => {
    if (instructorCourses) {
      const dataTable: TableData[] = [];

      instructorCourses.forEach((instructorCourse) => {
       const newRow: TableData = {
         courseName: instructorCourse.course.courseName,
         createdAt: instructorCourse.course.createdAt?formatDate(instructorCourse.course.createdAt): 'N/A',
         roster: instructorCourse.course.rosterStudentCount,
         type: instructorCourse.course.asyncEnabled ? 'Asyn' : 'Sync',
         assignmentCount: instructorCourse.assignmentCount,
         peerEvaluationAssginmentCount: instructorCourse.peerEvaluationOnlyAssignmentCount,
         instructorGradedOnlyAssignmentCount: instructorCourse.instructorGradedOnlyAssignmentCount,
       };
        dataTable.push(newRow);
      });
      setTableData(dataTable)
    }
  },[instructorCourses]);

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      sortBy="name"
      title={`Courses`}
      headingLevel={1}
      id="assignment-progress-card"
    />
  );
}

export default UserDetailsPage;
