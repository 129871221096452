import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Purchase, SharedPurchase } from '../../../types/types';
import { sharePurchase, unsharePurchase } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import AlertBar from '../../core/display/AlertBar';
import Avatar from '../../core/display/Avatar/Avatar';

interface Props {
  purchase: Purchase;
  updateData: () => void;
}

function SharePurchaseMenu({ purchase, updateData }: Props): JSX.Element {
  const [sharedPurchases, setSharedPurchases] = useState<SharedPurchase[]>(purchase.sharedPurchases);
  const [email, setEmail] = useState('');
  const [emailNotFound, setEmailNotFound] = useState(false);
  const [emailAlreadyShared, setEmailAlreadyShared] = useState(false);

  useEffect(() => {
    setEmailNotFound(false);
    setEmailAlreadyShared(false);
  }, [email]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const emailAlreadyShared =
        sharedPurchases.filter((sharedPurchase) => sharedPurchase.user.email === email).length > 0;

      if (emailAlreadyShared) setEmailAlreadyShared(true);
      else
        sharePurchase(
          purchase.purchaseId,
          email,
          (newPurchase: Purchase) => {
            setSharedPurchases(newPurchase.sharedPurchases);
            updateData();
            setEmail('');
            setEmailNotFound(false);
          },
          (error) => {
            if (error.response?.status === 404) {
              setEmailNotFound(true);
              return true;
            }
            return false;
          },
        );
    },
    [sharedPurchases, purchase.purchaseId, email, updateData],
  );

  return (
    <div id="sharing-menu">
      <p>
        Purchase: <b>{purchase.name}</b> <span>({moment(purchase.createdAt).format('L')})</span>
      </p>

      {emailNotFound ? <AlertBar>Email not found. Please try again.</AlertBar> : null}
      {emailAlreadyShared ? <AlertBar>Already shared with this email.</AlertBar> : null}

      <form onSubmit={handleSubmit}>
        <label htmlFor="email" className="sr-only">
          Email of user to share purchase
        </label>
        <input
          id="email"
          type="email"
          placeholder="Enter user via email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button variant="rad">Share</Button>
      </form>

      {sharedPurchases.length > 0 ? (
        <>
          <h2>Shared with:</h2>
          <table>
            <thead className="sr-only">
              <tr>
                <th>Avatar</th>
                <th>Name</th>
                <th>Seats Used</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {sharedPurchases.map((sharedPurchase) => (
                <tr key={sharedPurchase.sharedPurchaseId}>
                  <td>
                    <Avatar user={sharedPurchase.user} />
                  </td>
                  <td>
                    <b>{sharedPurchase.user.name}</b>
                  </td>
                  <td>
                    Used: {sharedPurchase.seatsUsed}/{purchase.seats} Seats
                  </td>
                  <td>
                    <Button
                      variant="alt sm low"
                      onClick={() =>
                        unsharePurchase(purchase.purchaseId, sharedPurchase.sharedPurchaseId, (newPurchase) => {
                          setSharedPurchases(newPurchase.sharedPurchases);
                          updateData();
                        })
                      }
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </div>
  );
}

export default SharePurchaseMenu;
