import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccessPermission, Assignment, AssignmentProgress } from '../../types/types';
import { formatDate, getPhaseStatus, PhaseStatus } from '../../utils/functions';
import Icon from '../core/display/Icon';
import Table, { getCellWithIndicators, getCellWithUnit } from '../core/display/Table/Table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ColumnDef } from '@tanstack/react-table';

interface Props {
  assignment?: Assignment;
  includeAssignmentNames?: boolean;
  progressData: AssignmentProgress[];
  showAllColumns?: boolean;
  accessPermission?: AccessPermission;
}

type TableData = {
  name: string;
  groupFormationResponseComplete?: string;
  email?: string;
  groupName?: string;
  assignmentName: string;
  time?: string;
  reviewsGiven?: number;
  reviewsReceived?: number;
  feedbackGiven?: number;
  feedbackReceived?: number;
  evaluationsGiven?: number;
  evaluationsReceived?: number;
  userId: string;
  assignmentId: string;
  grade: number | string;
  instructorReviewReceived: boolean;
  reflectionCompletion: number | string;
  selfReviewComplete?: boolean;
  selfEvalComplete?: boolean;
};

function ProgressTable({
  assignment,
  includeAssignmentNames = false,
  progressData,
  showAllColumns = false,
  accessPermission,
}: Props): JSX.Element {
  const { courseId } = useParams() as { courseId: string };
  const user = useSelector((state: RootState) => state.user);

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);

  const navigate = useNavigate();

  const handleRowSelect = useCallback(
    (userId: string, assignmentId: string) =>
      navigate(`/course/${courseId}/assignment/${assignmentId}/student/${userId}`),
    [courseId, navigate],
  );

  useEffect(() => {
    const phaseStatus = getPhaseStatus(assignment);
    const columns: ColumnDef<TableData>[] = [
      { header: 'Name', accessorKey: 'name', meta: { className: 'left-align' } },
    ];
    if (assignment?.groupFormationEnabled || showAllColumns) {
      columns.push({
        header: 'Group Survey',
        accessorKey: 'groupFormationResponseComplete',
      });
    }
    if (assignment?.groupsEnabled || showAllColumns) {
      columns.push({ header: 'Group', accessorKey: 'groupName', meta: { className: 'left-align' } });
    }
    if (phaseStatus.submission || showAllColumns) {
      columns.push({ header: 'Submission Time', accessorKey: 'time', meta: { className: 'left-align' } });
    }
    if (phaseStatus.review || showAllColumns || assignment?.instructorGradedOnly) {
      let reviewSubColumns: ColumnDef<TableData>[] = [];
      if (assignment?.instructorGradedOnly === false || showAllColumns) {
        reviewSubColumns = [{ header: 'Given', accessorKey: 'reviewsGiven' }];
      }
      if (assignment?.instructorUpload === false || showAllColumns) {
        reviewSubColumns.push({
          header: 'Received',
          accessorKey: 'reviewsReceived',
          cell: (cell) => {
            const value = cell.getValue() as number;
            if (value === -1) return '';
            if (cell.row.original.instructorReviewReceived) {
              return getCellWithIndicators(value, [
                <Icon
                  key="instructor-reviewed-icon"
                  className="instructor-reviewed-icon"
                  code="exposure_plus_1"
                  label="Reviewed by Instructor"
                  tooltip
                />,
              ]);
            }
            return value;
          },
        });
      }
      if (assignment?.selfReviewEnabled || showAllColumns) {
        reviewSubColumns.push({
          header: 'Self',
          accessorKey: 'selfReviewComplete',
          cell: (cell) => (cell.getValue() !== undefined ? (cell.getValue() ? 'Complete' : 'Incomplete') : ''),
        });
      }
      columns.push({
        header: 'Reviews',
        columns: reviewSubColumns,
      });
    }
    if (phaseStatus.feedback || showAllColumns) {
      columns.push({
        header: 'Feedback',
        columns: [
          { header: 'Given', accessorKey: 'feedbackGiven' },
          { header: 'Received', accessorKey: 'feedbackReceived' },
        ],
      });
    }
    if (phaseStatus.evaluate || showAllColumns) {
      let evaluationSubColumns: ColumnDef<TableData>[] = [
        { header: 'Given', accessorKey: 'evaluationsGiven' },
        { header: 'Received', accessorKey: 'evaluationsReceived' },
      ];
      if (assignment?.selfEvalEnabled || showAllColumns) {
        evaluationSubColumns.push({
          header: 'Self',
          accessorKey: 'selfEvalComplete',
          cell: (cell) => (cell.getValue() !== undefined ? (cell.getValue() ? 'Complete' : 'Incomplete') : ''),
        });
      }
      columns.push({
        header: 'Evaluations',
        columns: evaluationSubColumns,
      });
    }
    if (phaseStatus.reflection || showAllColumns) {
      columns.push({ header: 'Reflection', accessorKey: 'reflectionCompletion' });
    }
    if (accessPermission?.viewStudentGradePermission) {
      columns.push({
        header: 'Grade',
        accessorKey: 'grade',
        cell: (cell) => {
          const value = cell.getValue() as number;
          if (typeof value !== 'number') return value;
          return getCellWithUnit(value.toFixed(2), ' %');
        },
      });
    }

    if (includeAssignmentNames) {
      columns.splice(2, 0, {
        header: 'Assignment',
        accessorKey: 'assignmentName',
        meta: { className: 'left-align long-value' },
      });
    }
    setTableColumns(columns);
  }, [
    assignment,
    progressData,
    includeAssignmentNames,
    showAllColumns,
    user.role,
    accessPermission?.viewStudentGradePermission,
  ]);

  useEffect(() => {
    const assignmentPhaseStatusMap: { [index: string]: PhaseStatus } = {};
    const dataTable: TableData[] = [];
    progressData.forEach((progress) => {
      const { grade, asyncResult } = progress.result || {};
      const { assignment } = progress;
      let gradeForRow: number | string = 'N/A';
      if (grade) gradeForRow = grade.overallGrade;
      else if (asyncResult) gradeForRow = assignment.completionGrading ? 'Pass' : asyncResult.generatedGrade;

      const phaseStatus =
        assignmentPhaseStatusMap[assignment.assignmentId ?? ''] ?? getPhaseStatus(progress.assignment);
      assignmentPhaseStatusMap[assignment.assignmentId ?? ''] = phaseStatus;
      const newRow: TableData = {
        name: progress.user.sortableName,
        groupFormationResponseComplete: assignment.groupFormationEnabled
          ? progress.groupFormationResponseComplete
            ? 'Complete'
            : 'Incomplete'
          : undefined,
        email: progress.user.email ?? '',
        groupName: assignment.groupsEnabled ? (progress.group ? progress.group.groupName : 'N/A') : undefined,
        assignmentName: progress.assignment.assignmentName,
        time: phaseStatus.submission
          ? progress.submissionInfo
            ? formatDate(progress.submissionInfo.createdAt)
            : 'N/A'
          : undefined,
        reviewsGiven: phaseStatus.review ? progress.reviewsCompleted : undefined,
        reviewsReceived: phaseStatus.review || assignment.instructorGradedOnly ? progress.reviewsReceived : undefined,
        feedbackGiven: phaseStatus.feedback ? progress.feedbackCompleted : undefined,
        feedbackReceived: phaseStatus.feedback ? progress.feedbackReceived : undefined,
        evaluationsGiven: progress.countEvaluationsComplete,
        evaluationsReceived: progress.countEvaluationsReceived,
        userId: progress.user.userId,
        assignmentId: progress.assignment.assignmentId ? progress.assignment.assignmentId : '',
        reflectionCompletion: phaseStatus.reflection ? (progress.hasReflected ? `Complete` : `Incomplete`) : '',
        grade: gradeForRow,
        instructorReviewReceived: progress.instructorReviewReceived !== null,
        selfReviewComplete: assignment.selfReviewEnabled ? progress.selfReview?.complete ?? false : undefined,
        selfEvalComplete: assignment.selfEvalEnabled ? progress.selfEvalComplete : undefined,
      };

      dataTable.push(newRow);
    });

    setTableData(dataTable);
  }, [progressData]);

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      sortBy="name"
      title={`Assignment Progress - ${assignment ? assignment.assignmentName : 'All'}`}
      headingLevel={1}
      id="assignment-progress-card"
      defaultPageSize={50}
      informOfRow={(row) => handleRowSelect(row.original.userId, row.original.assignmentId)}
      hideDownload={accessPermission?.downloadResultPermission ? false : true}
    />
  );
}

export default ProgressTable;
