import React, { useState, useEffect, useMemo, ChangeEvent, useCallback } from 'react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { Assignment, AssignmentProgress, Course, ProgressStats, User, UserRole } from '../../types/types';
import {
  copyTextToClipboard,
  formDataToObjectParsed,
  getAssignmentTypeText,
  getPhaseStatus,
  getPhaseTimeLeft,
  setPageTitle,
  stringArrayIncludes,
} from '../../utils/functions';
import {
  deleteCourse,
  getCourseAssignments,
  getUserAssignmentProgressForCourse,
  publishCourseTemplate,
  sendCourseTemplateViaEmail,
} from '../../utils/requests';
import ProgressIcon from '../core/display/Progress/ProgressIcon';
import Button from '../core/button/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { assignmentCurrentStudentTask, TaskStatus } from '../dashboard/student/StudentDashboard';
import { assignmentCurrentTeacherTask } from '../dashboard/teacher/TeacherDashboard';
import { SCREEN_WIDTH_LAPTOP_SM } from '../../utils/constants';
import SearchBar from '../core/input/SearchBar/SearchBar';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import Icon from '../core/display/Icon';
import { selectCourse } from '../../store/selectors';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import CourseDataBar from './CourseDataBar';
import AlertBar from '../core/display/AlertBar';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { newUpdateKey } from '../../actions';

type Email = {
  name: string;
};

function AssignmentList(): JSX.Element {
  const user: User = useSelector((state: RootState) => state.user);
  const course = useSelector(selectCourse);

  useEffect(() => setPageTitle(`${course?.courseName ?? ''} - Course Dashboard`), [course]);
  const dispatch = useDispatch();

  const [allAssignments, setAllAssignments] = useState<Assignment[] | null>(null);
  const [activeAssignments, setActiveAssignments] = useState<Assignment[]>([]);
  const [completeAssignments, setCompleteAssignments] = useState<Assignment[]>([]);
  const [unpublishedAssignments, setUnpublishedAssignments] = useState<Assignment[]>([]);
  const [progressTable, setProgressTable] = useState<{ [index: string]: AssignmentProgress }>({});
  const [filterList, setFilterList] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState(''); // Raw Data

  const { courseId } = useParams() as { courseId: string };
  const { modalDispatch } = useModalContext();

  const selectedAssignments = useMemo(() => {
    if (course?.template) return allAssignments;
    if (stringArrayIncludes(filterList, 'Active')) return activeAssignments;
    else if (stringArrayIncludes(filterList, 'Past')) return completeAssignments;
    else if (stringArrayIncludes(filterList, 'Upcoming')) return unpublishedAssignments;
    return allAssignments;
  }, [course, filterList, allAssignments, activeAssignments, completeAssignments, unpublishedAssignments]);

  useEffect(() => {
    getCourseAssignments(courseId, setAllAssignments);
    getUserAssignmentProgressForCourse(courseId, setProgressTable);
  }, [courseId]);

  useEffect(() => {
    if (allAssignments) {
      const active: Assignment[] = [],
        complete: Assignment[] = [],
        unpublished: Assignment[] = [];
      allAssignments.forEach((assignment) => {
        switch (assignment.status) {
          case 'ACTIVE':
          case 'NEEDS_GRADING':
            active.push(assignment);
            break;
          case 'COMPLETE':
            complete.push(assignment);
            break;
          case 'UNPUBLISHED':
            unpublished.push(assignment);
        }
      });
      setActiveAssignments(active);
      setCompleteAssignments(complete);
      setUnpublishedAssignments(unpublished);
    }
  }, [allAssignments]);

  const editQueryParams = useMemo(
    () =>
      course
        ? new URLSearchParams({
            courseId: String(course.courseId),
            courseName: String(course.courseName),
            discipline: String(course.discipline),
            university: String(course.university),
            timeZone: String(course.timeZone),
            label: String(course.label),
            studentPurchaseEnabled: String(course.studentPurchaseEnabled),
            asyncEnabled: String(course.asyncEnabled),
          })
        : undefined,
    [course],
  );
  

  const deleteTemplate = () => {
    deleteCourse(
      courseId,
      () => {
        modalDispatch(
          openModal({
            heading: 'Course Template Deleted',
            label: 'Course Template has been deleted.',
            buttonText: 'Continue',
            cancelHide: true,
          }),
        );
      },
    );
  };

  const openSendCourseTemplateViaEmailModal = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Share course template via email',
          closeButton: true,
          padding: '2rem',
          buttonText: 'Send',
          form: true,
          onSubmit: (formData) => {
            const data = formDataToObjectParsed(formData) as Email;
            sendCourseTemplateViaEmail(
              courseId,
              data.name.toLowerCase(),
              (result) => {
                modalDispatch(
                  openModal({
                    heading: `Invite Result`,
                    label: `Course template ${result ? 'has successfully' : ' fail to'} send to ${data.name}.`,
                    inputType: 'none',
                    buttonText: 'Continue',
                    cancelHide: true,
                  }),
                );
              },
              (error) => {
                if (error.response?.status === 403)
                  modalDispatch(
                    openModal({
                      heading: `Access Error`,
                      label: 'Either you or the user with the email you send cannot access this course',
                    }),
                  );
              },
            );
          },
          children: (
            <div id="share-course-via-email-modal">
              <p>
                Enter the email to share with. Make sure the user with this email uses Peerceptiv and the user is either
                an admin or instructor.{' '}
              </p>
              <div className="email-wrapper">
                <input id="name" name="name" type="text" />
              </div>
            </div>
          ),
        }),
      ),
    [courseId, modalDispatch],
  );

  const openPublishTemplateModal = useCallback(() => {
    publishCourseTemplate(
      courseId,
      (result) => {
        modalDispatch(
          openModal({
            heading: `Template Pubilsh Result`,
            label: `Course template ${result ? 'has ' : ' fail to'} ${result ? 'published' : ' publish'}.`,
            inputType: 'none',
            buttonText: 'Continue',
            cancelHide: true,
          }),
        );
        dispatch(newUpdateKey());
      },
    );
  }, [courseId, dispatch, modalDispatch]);

  const loading = course === null || allAssignments === null;
  const ghostAssignments = Array.from({ length: 8 }, (v, i) => <AssignmentGhost key={`ghost-${i}`} index={i} />);

  const filteredAssignments = (selectedAssignments ?? []).filter(
    (assignment) => assignment.assignmentName.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) !== -1,
  );

  if (user.courseRole !== null && course) {
    return (
      <div className="page" id="course-page">
        {loading ? <LoadingSpinner /> : null}
        <div className="course-card-banner-wrapper">
          <div className="course-card-long">
            {course ? (
              <>
                <section className="card-main-section">
                  <p>
                    <i>
                      {course.semester} {course.year}
                    </i>
                  </p>
                  <h1>{course.courseName}</h1>
                  <p>{course.discipline}</p>
                  <p>
                    <i>{course.label}</i>
                  </p>
                </section>
                <section className="course-ctrls">
                  {user.courseRole === 'TEACHER' || user.courseRole==='TEACHING ASSISTANT' ? (
                    <Button
                      id="edit-course"
                      variant="alt-white"
                      href={`/course/${
                        course.template ? 'templates' : 'dashboard'
                      }/edit?${editQueryParams?.toString()}`}
                      route
                      ariaLabel="Edit Course"
                      tooltip="Edit Course"
                    >
                      <Icon code="settings" ariaHidden />
                    </Button>
                  ) : null}
                  <Button
                    id="share-course-code"
                    variant="alt-white"
                    onClick={() => {
                      modalDispatch(
                        openModal({
                          heading: 'Share Course Code',
                          form: false,
                          noActionButtons: true,
                          closeButton: true,
                          padding: '2rem',
                          children: (
                            <div id="share-course-code-modal">
                              <p>Below is the code for joining this course:</p>
                              <div className="code-wrapper">
                                <pre>{course.enrollmentPassword}</pre>
                                <Button
                                  type="button"
                                  className="button-mini"
                                  classOverride
                                  onClick={() => copyTextToClipboard(course.enrollmentPassword ?? '')}
                                  tooltip="Copy Code"
                                >
                                  <Icon code="content_copy" />
                                </Button>
                              </div>
                            </div>
                          ),
                        }),
                      );
                    }}
                    ariaLabel="Share Course Code"
                    tooltip="Share Course Code"
                  >
                    <Icon code="share" ariaHidden />
                  </Button>
                  <Button
                    id="share-course-by-mail"
                    onClick={openSendCourseTemplateViaEmailModal}
                    variant="alt-white"
                    ariaLabel="Share Course Code"
                    tooltip="Share via email"
                  >
                    <Icon code="mail" ariaHidden />
                  </Button>
                  {course.template  && user.userId === course.user?.userId? (
                    <Button
                      id="delete-template"
                      onClick={deleteTemplate}
                      variant="alt-white"
                      href="/course/dashboard"
                      route
                      ariaLabel="Delete Course"
                      tooltip="Delete Course"
                    >
                      <Icon code="delete" ariaHidden />
                    </Button>
                  ) : null}

                  <Button
                    variant="alt-white"
                    href="/course/dashboard"
                    route
                    ariaLabel="Exit Course"
                    tooltip="Exit Course"
                  >
                    <Icon code="exit_to_app" ariaHidden />
                  </Button>
                </section>
              </>
            ) : null}

            <div className="watermark-wrapper">
              <Icon className="watermark" code="psychology" ariaHidden />
            </div>
          </div>
        </div>

        {(user.courseRole === 'TEACHER'|| user.courseRole==='TEACHING ASSISTANT') && !course.template ? <CourseDataBar /> : null}
        {course.template ? (
          <AlertBar backgroundColor="#c1daf7">
            This page represents a Course Template. This course is not active.
          </AlertBar>
        ) : null}

        <section className="assignment-list-content">
          <div className="ctrls">
            {course.template && !course.published ? (
              <Button id="new-assignment-btn" variant="low" onClick={openPublishTemplateModal}>
                <Icon code="publish" ariaHidden /> Publish Template
              </Button>
            ) : null}
            {user.courseRole === 'TEACHER' || user.courseRole==='TEACHING ASSISTANT' ? (
              <Button id="new-assignment-btn" variant="low" href={`/course/${courseId}/assignment/setup/menu`} route>
                <Icon code="add" ariaHidden /> New Assignment
              </Button>
            ) : null}

            {!course.template ? (
              <FilterTab label="Show:" hideLabel setFilterList={setFilterList}>
                <FilterTab.Button id="btn-all" type="radio" name="assignment-filters" defaultChecked={true}>
                  All ({(allAssignments ?? []).length})
                </FilterTab.Button>
                <FilterTab.Button id="btn-active" type="radio" name="assignment-filters" defaultChecked={false}>
                  Active ({activeAssignments.length})
                </FilterTab.Button>
                <FilterTab.Button id="btn-past" type="radio" name="assignment-filters" defaultChecked={false}>
                  Past ({completeAssignments.length})
                </FilterTab.Button>
                {user.courseRole !== 'STUDENT' ? (
                  <FilterTab.Button id="btn-upcoming" type="radio" name="assignment-filters" defaultChecked={false}>
                    Upcoming ({unpublishedAssignments.length})
                  </FilterTab.Button>
                ) : null}
              </FilterTab>
            ) : null}

            <SearchBar
              placeholder="Search Assignments"
              value={searchValue}
              setValue={setSearchValue}
              resultsLength={filteredAssignments.length}
            />
          </div>

          {searchValue !== '' ? (
            <p id="assignment-search-query-label">
              Showing results for &apos;<i id="search-query">{searchValue}</i>&apos;:
            </p>
          ) : null}

          <div className="list-wrapper">
            {searchValue === '' ? (
              <ul aria-labelledby="active-heading">
                {selectedAssignments !== null ? (
                  selectedAssignments.length > 0 ? (
                    selectedAssignments.map((assignment) => (
                      <AssignmentCard
                        key={assignment.assignmentId}
                        assignment={assignment}
                        course={course}
                        role={user.courseRole as UserRole}
                        progress={progressTable[assignment.assignmentId as string]}
                      />
                    ))
                  ) : (
                    <li className="no-results">No assignments to show</li>
                  )
                ) : (
                  ghostAssignments
                )}
              </ul>
            ) : (
              <ul aria-labelledby="assignment-search-query-label">
                {allAssignments !== null ? (
                  filteredAssignments.length > 0 ? (
                    filteredAssignments.map((assignment, i) => (
                      <AssignmentCard
                        key={`unpublished-assignment-${i}`}
                        assignment={assignment}
                        course={course}
                        role={user.courseRole as UserRole}
                        progress={progressTable[assignment.assignmentId as string]}
                      />
                    ))
                  ) : (
                    <li className="no-results">No results to show</li>
                  )
                ) : (
                  ghostAssignments
                )}
              </ul>
            )}
          </div>
        </section>
      </div>
    );
  }
  return <LoadingSpinner />;
}

interface AssignmentCardProps {
  assignment: Assignment;
  course: Course | null;
  progress?: AssignmentProgress;
  role: UserRole;
}

function AssignmentCard({ assignment, course, progress, role }: AssignmentCardProps): JSX.Element {
  const submissionText = getAssignmentTypeText(assignment);

  const { asyncEnabled, progressStats, status } = assignment;
  let iconClass = '',
    iconCode = '',
    ariaLabel = '';
  if (assignment.template) {
    iconClass = 'scheduled';
    iconCode = 'assignment';
    ariaLabel = 'Template';
  } else if (asyncEnabled && status === 'ACTIVE') {
    switch (role) {
      case 'STUDENT':
        if (progress)
          if (progress.status.submissionPriority) {
            iconClass = 'submit';
            iconCode = 'file_upload';
            ariaLabel = 'Submission Phase';
          } else if (progress.status.reviewPriority) {
            iconClass = 'review';
            iconCode = 'rate_review';
            ariaLabel = 'Review Phase';
          } else if (progress.status.feedbackPriority) {
            iconClass = 'feedback';
            iconCode = 'question_answer';
            ariaLabel = 'Feedback Phase';
          } else if (progress.status.reflectionPriority) {
            iconClass = 'reflection';
            iconCode = 'edit_square';
            ariaLabel = 'Reflection Phase';
          } else if (progress.status.hasResult) {
            iconClass = 'complete';
            iconCode = 'done';
            ariaLabel = 'Complete';
          } else if (progress.reviewsCompleted && progress.feedbackCompleted) {
            iconClass = 'async';
            iconCode = 'hourglass_empty';
            ariaLabel = 'Waiting';
          }
        break;
      default:
        iconClass = 'async';
        iconCode = 'alarm_off';
        ariaLabel = 'Asynchronoyus';
        break;
    }
  } else {
    if (status && progressStats) {
      switch (status) {
        case 'ACTIVE':
          if (progressStats.submissionPhase) {
            iconClass = 'submit';
            iconCode = 'file_upload';
            ariaLabel = 'Submission Phase';
          } else if (progressStats.reviewPhase) {
            iconClass = 'review';
            iconCode = 'rate_review';
            ariaLabel = 'Review Phase';
          } else if (progressStats.feedbackPhase) {
            iconClass = 'feedback';
            iconCode = 'question_answer';
            ariaLabel = 'Feedback Phase';
          } else if (progressStats.evalPhase) {
            iconClass = 'evaluate';
            iconCode = 'supervisor_account';
            ariaLabel = 'Evaluation Phase';
          } else if (progressStats.reflectionPhase) {
            iconClass = 'reflection';
            iconCode = 'edit_square';
            ariaLabel = 'Reflection Phase';
          } else if (progressStats.groupFormationPhase) {
            iconClass = 'submit';
            iconCode = 'groups';
            ariaLabel = 'Group Formation Phase';
          }
          break;
        case 'NEEDS_GRADING':
          iconClass = 'grading';
          iconCode = 'pending_actions';
          ariaLabel = 'Awaiting Grades';
          break;
        case 'COMPLETE':
          iconClass = 'complete';
          iconCode = 'done';
          ariaLabel = 'Complete';
          break;
        case 'UNPUBLISHED':
          iconClass = 'scheduled';
          iconCode = 'event';
          ariaLabel = 'Unpublished';
          break;
        default:
      }
    }
  }

  const { assignmentId, courseId } = assignment;
  return (
    <li className="assignment-card">
      <div className="left-wrapper">
        <div className={'assignment-icon ' + iconClass} role="img" aria-label={ariaLabel}>
          <Icon code={iconCode} ariaHidden />
        </div>
        <div className="main-info">
          <h3>
            <Link to={`/course/${courseId}/assignment/${assignmentId}/dashboard`}>{assignment.assignmentName}</Link>
          </h3>
          <p>{submissionText}</p>
        </div>
      </div>
      <DynamicWrapper>
        <div className="timeline-wrapper">
          {progressStats && course && course.rosterStudentCount !== undefined && progress ? (
            <PhaseProgress
              progressStats={progressStats}
              studentCount={course.rosterStudentCount}
              assignment={assignment}
              progress={progress}
              role={role}
            />
          ) : null}
        </div>
        <div className="task-wrapper">
          {progress ? <CurrentTask assignment={assignment} progress={progress} role={role} /> : null}
        </div>
      </DynamicWrapper>
    </li>
  );
}

function DynamicWrapper({ children }: { children: React.ReactNode }): JSX.Element {
  const [mobile, setMobile] = useState(document.documentElement.clientWidth < SCREEN_WIDTH_LAPTOP_SM);

  useEffect(() => {
    const handleResize = () => {
      const w = document.documentElement.clientWidth;
      if (w < SCREEN_WIDTH_LAPTOP_SM) setMobile(true);
      else setMobile(false);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setMobile]);

  if (mobile) return <div className="right-wrapper">{children}</div>;
  return <>{children}</>;
}

interface PhaseProgressProps {
  assignment: Assignment;
  progress: AssignmentProgress;
  progressStats: ProgressStats;
  role: UserRole;
  studentCount: number;
}

function PhaseProgress({ assignment, progress, progressStats, role, studentCount }: PhaseProgressProps): JSX.Element {
  const participantCount = assignment.groupsEnabled ? progressStats.countGroups : studentCount;
  let submissionPercent = 0,
    reviewPercent = 0,
    feedbackPercent = 0,
    reflectionPercent = 0,
    submissionTooltip = ``,
    reviewTooltip = ``,
    feedbackTooltip = ``,
    reflectionTooltip = ``;
  switch (role) {
    case 'TEACHER':
    case 'TEACHING ASSISTANT':
      submissionPercent = (progressStats.submissionCount / progressStats.maxSubmissionsPossible) * 100;
      if (isNaN(submissionPercent)) submissionPercent = 0;
      reviewPercent = (progressStats.reviewCount / (participantCount * assignment.numberOfReviewers)) * 100;
      if (isNaN(reviewPercent)) reviewPercent = 0;
      feedbackPercent = (progressStats.feedbackCount / (participantCount * assignment.numberOfReviewers)) * 100;
      if (isNaN(feedbackPercent)) feedbackPercent = 0;
      reflectionPercent = (progressStats.reflectionCount / participantCount) * 100;
      if (isNaN(reflectionPercent)) reflectionPercent = 0;
      submissionTooltip = `${progressStats.submissionCount} / ${progressStats.maxSubmissionsPossible} (${Math.round(
        submissionPercent ?? 0,
      )}%)`;
      reviewTooltip = `${progressStats.reviewCount} / ${participantCount * assignment.numberOfReviewers} (${Math.round(
        reviewPercent ?? 0,
      )}%)`;
      feedbackTooltip = `${progressStats.feedbackCount} / ${
        participantCount * assignment.numberOfReviewers
      } (${Math.round(feedbackPercent ?? 0)}%)`;
      reflectionTooltip = `${progressStats.reflectionCount} / ${participantCount} (${Math.round(
        reflectionPercent ?? 0,
      )}%)`;
      break;
    case 'STUDENT':
      if (progress) {
        submissionPercent = (progress.status.hasSubmitted ? 1 : 0) * 100;
        reviewPercent = (progress.reviewsCompleted / assignment.numberOfReviewers) * 100;
        feedbackPercent =
          (progress.feedbackCompleted / Math.max(assignment.numberOfReviewers, progress.reviewsReceived)) * 100;
        reflectionPercent = (progress.status.hasReflected ? 1 : 0) * 100;
        submissionTooltip = progress.status.hasSubmitted
          ? 'Submitted'
          : progress.status.canSubmit
          ? 'Not yet submitted'
          : 'Missed submission';
        reviewTooltip = `${progress.reviewsCompleted}/${assignment.numberOfReviewers} Reviews`;
        feedbackTooltip = `${progress.feedbackCompleted}/${Math.max(
          assignment.numberOfReviewers,
          progress.reviewsReceived,
        )} Feedback`;
        reflectionTooltip = `${progress.status.hasReflected ? 1 : 0} Reflections`;
      }
  }

  const { submission, review, feedback } = getPhaseStatus(assignment);

  let showSubmit = true;
  let submitReviewBarOpacity = 1;
  let showReview = true;
  let reviewFeedbackBarOpacity = 1;
  let showFeedback = true;
  let feedbackRefectBarOpacity = 1;
  let showReflection = true;

  if (progressStats.submissionPhase || assignment.status === 'UNPUBLISHED') {
    submitReviewBarOpacity = 0.25;
    reviewFeedbackBarOpacity = 0.25;
    feedbackRefectBarOpacity = 0.25;
  } else if (progressStats.reviewPhase) {
    reviewFeedbackBarOpacity = 0.25;
    feedbackRefectBarOpacity = 0.25;
  }
  if (progressStats.asyncPhase || assignment.template) {
    submitReviewBarOpacity = 0.25;
    reviewFeedbackBarOpacity = 0.25;
    feedbackRefectBarOpacity = 0.25;
  }
  if (!submission) {
    showSubmit = false;
    submitReviewBarOpacity = 0;
  }
  if (!review) {
    showReview = false;
    submitReviewBarOpacity = 0;
    reviewFeedbackBarOpacity = 0;
    feedbackRefectBarOpacity = 0;
  }
  if (!feedback) {
    showFeedback = false;
    reviewFeedbackBarOpacity = 0;
    feedbackRefectBarOpacity = 0;
  }

  const { submissionDeadline, reviewDeadline, feedbackDeadline, reflectionDeadline } = assignment;
  const submitMoment = moment(submissionDeadline);
  const reviewMoment = moment(reviewDeadline);
  const feedbackMoment = moment(feedbackDeadline);
  const reflectionMoment = moment(reflectionDeadline);
  const momentNow = moment();
  let nextDeadlineMoment = momentNow;
  let minDiff = Number.MAX_SAFE_INTEGER;
  const deadlineMoments = [submitMoment, reviewMoment, feedbackMoment, reflectionMoment];
  deadlineMoments.forEach((currMoment) => {
    const currDiff = momentNow.diff(currMoment);
    if (currDiff < 0) {
      const absCurrDiff = Math.abs(currDiff);
      if (absCurrDiff < minDiff) {
        nextDeadlineMoment = currMoment;
        minDiff = absCurrDiff;
      }
    }
  });

  const getDeadlineSpan = (deadlineMoment: moment.Moment) =>
    assignment.template === true ? null : (
      <span className={`deadline ${nextDeadlineMoment === deadlineMoment ? 'current' : ''}`}>
        {deadlineMoment.isValid() ? deadlineMoment.format('MMM D') : ''}
      </span>
    );
  const templateProps = assignment.template ? { percent: 100, tooltip: undefined } : {};
  return (
    <div className="phase-progress">
      <div className={`phase-wrapper${showSubmit ? '' : ' hide-phase'}`}>
        <ProgressIcon
          phase="submit"
          color="#7878F1"
          backgroundColor="#DDDDFB"
          stroke="#6363EE"
          percent={submissionPercent}
          tooltip={submissionTooltip}
          units="Submissions Uploaded"
          {...templateProps}
        >
          <SubmissionSVG />
        </ProgressIcon>
        {getDeadlineSpan(submitMoment)}
      </div>
      <span className="submit-review-bar" style={{ opacity: submitReviewBarOpacity }} />
      <div className={`phase-wrapper${showReview ? '' : ' hide-phase'}`}>
        <ProgressIcon
          phase="review"
          color="#E676E3"
          backgroundColor="#F9DDF8"
          stroke="#CB25C5"
          percent={reviewPercent}
          tooltip={reviewTooltip}
          units="Reviews Sent"
          {...templateProps}
        >
          <ReviewSVG />
        </ProgressIcon>
        {getDeadlineSpan(reviewMoment)}
      </div>
      <span className="review-feedback-bar" style={{ opacity: reviewFeedbackBarOpacity }} />
      <div className={`phase-wrapper${showFeedback ? '' : ' hide-phase'}`}>
        <ProgressIcon
          phase="feedback"
          color="#E4C445"
          backgroundColor="#F8F0D0"
          stroke="#8A7314"
          percent={feedbackPercent}
          tooltip={feedbackTooltip}
          units="Feedback Sent"
          {...templateProps}
        >
          <FeedbackSVG />
        </ProgressIcon>
        {getDeadlineSpan(feedbackMoment)}
      </div>
      {assignment.reflectionEnabled ? (
        <>
          <span className="feedback-reflection-bar" style={{ opacity: feedbackRefectBarOpacity }} />
          <div className={`phase-wrapper${showReflection ? '' : ' hide-phase'}`}>
            <ProgressIcon
              phase="reflection"
              color="#0096FF"
              backgroundColor="#68E5DB"
              stroke="#00524B"
              percent={reflectionPercent}
              tooltip={reflectionTooltip}
              units="Reflections"
              {...templateProps}
            >
              <ReflectionSVG />
            </ProgressIcon>
            {getDeadlineSpan(reflectionMoment)}
          </div>
        </>
      ) : null}
    </div>
  );
}

interface CurrentTaskProps {
  assignment: Assignment;
  progress: AssignmentProgress;
  role: UserRole;
}

function CurrentTask({ assignment, progress, role }: CurrentTaskProps): JSX.Element {
  const { asyncEnabled } = assignment;

  let task: TaskStatus = { text: '' };
  switch (role) {
    case 'STUDENT':
      task = assignmentCurrentStudentTask(assignment, progress);
      break;
    case 'TEACHER':
    case 'TEACHING ASSISTANT':
      task = assignmentCurrentTeacherTask(assignment);
  }

  const timeLeft = getPhaseTimeLeft(assignment);

  if (task.href)
    return (
      <div className="btn-wrapper">
        <Button className="next-task-btn" variant="rad low" href={task.href} onClick={(e) => e.stopPropagation()} route>
          {task.text}
        </Button>
        {timeLeft.length > 0 ? (
          <p className="time-left">
            {asyncEnabled ? 'Assignment' : 'Task'} due in {timeLeft[0]}
          </p>
        ) : (
          ''
        )}
      </div>
    );
  return <p>{task.text}</p>;
}

interface GhostProps {
  index: number;
}

function AssignmentGhost({ index }: GhostProps): JSX.Element {
  return <li className="assignment-ghost ghost" style={{ animationDelay: `${index * 0.2}s` }} />;
}

const SubmissionSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height="32px"
    viewBox="0 0 24 24"
    width="32px"
    fill="white"
    stroke="#7878F1"
  >
    <g>
      <path d="M5,20h14v-2H5V20z M5,10h4v6h6v-6h4l-7-7L5,10z" />
    </g>
  </svg>
);

const ReviewSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 0 24 24" width="32px" fill="white" stroke="#E676E3">
    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 14v-2.47l6.88-6.88c.2-.2.51-.2.71 0l1.77 1.77c.2.2.2.51 0 .71L8.47 14H6zm12 0h-7.5l2-2H18v2z" />
  </svg>
);

const FeedbackSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 0 24 24" width="32px" fill="white" stroke="#E4C445">
    <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" />
  </svg>
);

const ReflectionSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 0 24 24" width="32px" fill="white" stroke="#3CB371">
    <g>
      <path d="M7,17V9.93L13.93,3l0,0H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-8.93L14.07,17H7z" />
      <polygon points="9,15 13.24,15 20.44,7.8 16.2,3.56 9,10.76" />
      <path d="M22.91,2.49l-1.41-1.41c-0.78-0.78-2.05-0.78-2.83,0l-1.06,1.06l4.24,4.24l1.06-1.06C23.7,4.54,23.7,3.27,22.91,2.49z" />
    </g>
  </svg>
);

export default AssignmentList;
