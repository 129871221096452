import React, { useEffect } from 'react';
import { Assignment, AssignmentProgress, PhaseCode, ProgressStats } from '../../../types/types';
import PhaseMenu, { PhaseMenuDetails } from './PhaseMenu';
import moment from 'moment';

interface Props {
  assignment: Assignment;
  userProgress: AssignmentProgress;
  setPhaseDetails: (phase: PhaseCode, details: PhaseMenuDetails) => void;
}

function SubmitMenu({ assignment, userProgress, setPhaseDetails }: Props): JSX.Element {
  const rootPath = `/course/${assignment.courseId}/assignment/${assignment.assignmentId}`;
  const { progressStats, asyncEnabled } = assignment;
  const { status, submissionInfo } = userProgress;
  const { reviewPhase, submissionPhase } = progressStats as ProgressStats;
  const dueDate = assignment.asyncEndDeadline ?? assignment.submissionDeadline ?? '';

  useEffect(
    () =>
      setPhaseDetails('submit', {
        complete: status.hasSubmitted,
        active: asyncEnabled ? status.submissionPriority : submissionPhase,
        locked: false,
        deadline: dueDate,
      }),
    [asyncEnabled, dueDate, reviewPhase, setPhaseDetails, status, submissionPhase, userProgress],
  );

  const getSubmissionTime = () => {
    if (userProgress.submissionInfo)
      return `Submitted: ${moment.utc(userProgress.submissionInfo.createdAt).local().format('l, LT')}`;
    return 'Not Submitted';
  };

  const getTaskName = () => {
    if (status.hasSubmitted) return getSubmissionTime();
    return 'Upload Your Submission';
  };

  const getTaskButtonText = () => {
    if (status.hasSubmitted) return 'View Submission';
    if (status.missedSubmitting && !status.canLateSubmit) return 'Missed Submission';
    if (status.missedSubmitting && status.canLateSubmit) return 'Submit Late';
    if (assignment.groupsEnabled) return 'Group Submit';
    return 'Submit';
  };

  const cantSubmit = status.missedSubmitting && !status.canLateSubmit;

  return (
    <PhaseMenu.SubMenu
      description={
        <>
          <b>Submission Phase</b> - Complete your submission according to the assignment description provided by your
          instructor.
        </>
      }
    >
      <PhaseMenu.Status
        phaseGoal="Enter your submission to complete this phase"
        complete={status.hasSubmitted}
        dueDate={dueDate}
        iconCode="file_upload"
      />
      <PhaseMenu.TaskList phase="submit" status={status}>
        <PhaseMenu.TaskEntry
          name={getTaskName()}
          buttonText={getTaskButtonText()}
          disabled={cantSubmit}
          href={
            cantSubmit
              ? undefined
              : submissionInfo
              ? `${rootPath}/submission/${submissionInfo.submissionId}`
              : `${rootPath}/submission`
          }
          incomplete={cantSubmit}
          complete={status.hasSubmitted}
        />
      </PhaseMenu.TaskList>
    </PhaseMenu.SubMenu>
  );
}

export default SubmitMenu;
