import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { Purchase, SharedPurchase, User } from '../../../types/types';
import { formatDollar, setPageTitle } from '../../../utils/functions';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import Table from '../../core/display/Table/Table';
import FilterTab from '../../core/layout/FilterTab/FilterTab';
import SharePurchaseMenu from '../purchasing/SharePurchaseMenu';
import { ColumnDef } from '@tanstack/react-table';

type TableData = {
  purchase: Purchase;
  purchaseId: string;
  name: string;
  date: string;
  price: number;
  seats: number;
  seatsRemaining: number;
  active: boolean;
  refunded: boolean;
  status: string;
  shares: SharedPurchase[];
};

interface Props {
  purchases: Purchase[];
  sharedPurchases: Purchase[];
  updateData: () => void;
  user: User;
}

function PurchaseHistory({ purchases, sharedPurchases, updateData, user }: Props): JSX.Element {
  useEffect(() => setPageTitle('Profile: Purchase History'), []);

  const [myPurchasesTableData, setMyPurchasesTableData] = useState<TableData[]>([]);
  const [sharedPurchasesTableData, setSharedPurchasesTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);

  const { modalDispatch } = useModalContext();

  const openShareMenu = useCallback(
    (purchase: Purchase) => {
      modalDispatch(
        openModal({
          heading: 'Share Purchase',
          closeButton: true,
          noActionButtons: true,
          form: false,
          children: <SharePurchaseMenu purchase={purchase} updateData={updateData} />,
        }),
      );
    },
    [modalDispatch, updateData],
  );

  useEffect(() => {
    const columns: ColumnDef<TableData>[] = [
      {
        header: 'Date',
        accessorKey: 'date',
        cell: (cell) => moment(cell.getValue() as string).format('lll'),
        meta: { className: 'left-align' },
      },
      { header: 'Name', accessorKey: 'name', meta: { className: 'left-align' } },
      {
        header: 'Price',
        accessorKey: 'price',
        cell: (cell) => formatDollar(cell.getValue() as number),
      },
      { header: 'Status', accessorKey: 'status' },
    ];
    if (user.role === 'TEACHER') {
      columns.splice(2, 0, {
        header: 'Seats',
        columns: [
          { header: 'Unused', accessorKey: 'seatsRemaining' },
          { header: 'Total', accessorKey: 'seats' },
        ],
      });
      columns.push({
        header: 'Sharing',
        accessorKey: 'shares',
        cell: (cell) => {
          if (cell.row.original.purchase.user.userId === user.userId)
            return (
              <Button
                className="share-btn"
                variant="sm"
                onClick={() => openShareMenu(cell.row.original.purchase)}
                ariaLabel="Share purchase"
              >
                <Icon code="people" />
                {cell.row.original.shares.length}
              </Button>
            );
          return <>Shared by {user.name}</>;
        },
      });
    }

    const parsePurchasesToTableData = (purchaseList: Purchase[]): TableData[] => {
      const dataTable: TableData[] = [];
      purchaseList.forEach((purchase) => {
        const newRow: TableData = {
          purchase: purchase,
          purchaseId: purchase.purchaseId,
          name: purchase.name,
          date: purchase.createdAt,
          price: purchase.price,
          seats: purchase.seats,
          seatsRemaining: purchase.seatsRemaining,
          active: purchase.active,
          refunded: purchase.refunded,
          status: `${purchase.active ? 'Active' : 'Inactive'}${purchase.refunded ? ', Refunded' : ''}`,
          shares: purchase.sharedPurchases,
        };

        dataTable.push(newRow);
      });
      return dataTable;
    };

    setMyPurchasesTableData(parsePurchasesToTableData(purchases));
    setSharedPurchasesTableData(parsePurchasesToTableData(sharedPurchases));
    setTableColumns(columns);
  }, [purchases, sharedPurchases, user, openShareMenu]);

  const tableData = filterList.includes('Shared With Me') ? sharedPurchasesTableData : myPurchasesTableData;

  return (
    <>
      {user.role === 'TEACHER' ? (
        <FilterTab label="Show:" setFilterList={setFilterList}>
          <FilterTab.Button id="btn-mine" type="radio" name="purchase-filters" defaultChecked={true}>
            My Purchases
          </FilterTab.Button>
          <FilterTab.Button id="btn-shared" type="radio" name="purchase-filters">
            Shared With Me
          </FilterTab.Button>
        </FilterTab>
      ) : null}

      {tableData.length > 0 ? (
        <Table
          columns={tableColumns}
          data={tableData}
          sortBy="date"
          title="Purchase History"
          id="purchase-history-table"
          noWrapper
        />
      ) : (
        <p id="no-purchases">No purchases to show</p>
      )}
    </>
  );
}

export default PurchaseHistory;
