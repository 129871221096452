import FocusTrap from 'focus-trap-react';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import Button from '../../button/Button/Button';
import Icon from '../Icon';

export interface ModalProps {
  buttonText?: string;
  cancelHide?: boolean;
  cancelText?: string;
  children?: React.ReactNode;
  close?: () => void;
  closeButton?: boolean;
  confirmHref?: string;
  form?: boolean;
  heading: string;
  headingFontSize?: string;
  headingHide?: boolean;
  inputType?: 'text' | 'none';
  justifyModal?: 'center' | 'flex-start';
  label?: string;
  maxWidth?: string;
  noActionButtons?: boolean;
  noCancel?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  onSubmit?: (arg0: FormData) => void;
  padding?: string;
  reset?: boolean;
  width?: string | number;
}

function Modal({
  buttonText = 'Confirm',
  cancelHide = false,
  cancelText = 'Cancel',
  children,
  close = () => undefined,
  closeButton,
  confirmHref,
  form = true,
  heading,
  headingFontSize,
  headingHide = false,
  inputType = 'none',
  justifyModal = 'center',
  label,
  maxWidth,
  noActionButtons,
  noCancel,
  onCancel = () => undefined,
  onConfirm,
  onSubmit,
  padding,
  reset = false,
  width,
}: ModalProps): JSX.Element {
  const overlayEl = useRef<HTMLDivElement>(null);
  const headingEl = useRef<HTMLHeadingElement>(null);
  const inputEl = useRef<HTMLInputElement>(null);
  const uniqueId = useRef(_.uniqueId());

  const renderTextInput = () => {
    return (
      <label className="flex-col">
        <b>{label}</b>
        <input className="modal-input" ref={inputEl} type="text" name="result" required={true} />
      </label>
    );
  };

  const renderNoInput = () => {
    return label ? <p>{label}</p> : <></>;
  };

  let renderInput;
  switch (inputType) {
    case 'text':
      renderInput = renderTextInput;
      break;
    case 'none':
      renderInput = renderNoInput;
      break;
    default:
      renderInput = renderNoInput;
      break;
  }

  useEffect(() => {
    document.body.classList.add('no-scroll');
    requestAnimationFrame(() => {
      if (inputEl.current) inputEl.current.focus();
    });

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && !noCancel) {
        close();
        onCancel();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [close, noCancel, onCancel]);

  useEffect(() => {
    headingEl.current?.focus();
  }, [heading]);

  return (
    <div
      ref={overlayEl}
      role="dialog"
      aria-labelledby={`modal-heading-${uniqueId.current}`}
      className="modal-overlay"
      onMouseDown={(e) => {
        const target = e.target as Node;
        if (!noCancel && target.isEqualNode(overlayEl.current)) {
          close();
          onCancel();
        }
      }}
      style={{ justifyContent: justifyModal }}
    >
      <ModalContainer form={form} onSubmit={onSubmit} reset={reset} close={close} style={{ width, maxWidth, padding }}>
        <h1
          ref={headingEl}
          id={`modal-heading-${uniqueId.current}`}
          className={headingHide ? 'sr-only' : undefined}
          style={{ fontSize: headingFontSize }}
          tabIndex={-1}
        >
          {heading}
        </h1>
        {closeButton ? (
          <button type="button" className="close-btn button-mini" onClick={close} aria-label="Close">
            <Icon code="close" />
          </button>
        ) : null}
        {renderInput()}
        {children !== undefined ? <div className="modal-content">{children}</div> : null}
        {!noActionButtons ? (
          <div className="ctrls">
            <Button
              type={onConfirm ? 'button' : undefined}
              onClick={() => {
                if (onConfirm) onConfirm();
                if (!onSubmit) close();
              }}
              href={confirmHref}
              route={confirmHref !== undefined}
            >
              {buttonText}
            </Button>
            {noCancel ? (
              ''
            ) : (
              <Button
                className={cancelHide ? `sr-only` : undefined}
                onClick={() => {
                  close();
                  onCancel();
                }}
                variant="alt"
                type="button"
              >
                {cancelText}
              </Button>
            )}
          </div>
        ) : null}
      </ModalContainer>
    </div>
  );
}

interface ModalContainerProps {
  children: React.ReactNode;
  close: () => void;
  form: boolean;
  onSubmit?: (arg0: FormData) => void;
  reset?: boolean;
  style: React.CSSProperties;
  width?: string | number;
}

function ModalContainer({ children, close, form, onSubmit, reset, style }: ModalContainerProps): JSX.Element {
  const formEl = useRef<HTMLFormElement>(null);

  if (form) {
    return (
      <FocusTrap>
        <form
          ref={formEl}
          className="panel"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (formEl.current) {
              document.body.classList.remove('no-scroll');
              const formData = new FormData(formEl.current);
              if (onSubmit) onSubmit(formData);
            }
            if (reset) (e.target as HTMLFormElement).reset();
            close();
          }}
          style={style}
        >
          {children}
        </form>
      </FocusTrap>
    );
  }
  return (
    <FocusTrap>
      <div
        className="panel"
        style={style}
        onSubmit={(e) => {
          e.stopPropagation();
          close();
        }}
      >
        {children}
      </div>
    </FocusTrap>
  );
}

export default Modal;
