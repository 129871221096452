import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccessPermission, Report } from '../../types/types';
import Button from '../core/button/Button/Button';
import Table from '../core/display/Table/Table';
import { ColumnDef } from '@tanstack/react-table';

type TableData = {
  reportee: string;
  reason: string;
  comment: string;
  time: string;
  reporter: string;
  status: string;
  report: Report;
};

interface Props {
  reports: Report[];
  accessPermission: AccessPermission;
}

function ReportsTable({ reports, accessPermission }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as {
    courseId: string;
    assignmentId: string;
  };

  const rootPath = `/course/${courseId}/assignment/${assignmentId}`;

  const navigate = useNavigate();

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);

  useEffect(() => {
    const columns: ColumnDef<TableData>[] = [
      { header: 'Reportee', accessorKey: 'reportee', meta: { className: 'left-align' } },
      { header: 'Reason', accessorKey: 'reason', meta: { className: 'left-align' } },
      {
        header: 'Comment',
        accessorKey: 'comment',
        cell: (cell) => {
          const value = cell.getValue() as string;
          if (value) return <div style={{ maxWidth: '200px', pointerEvents: 'none' }}>{value}</div>;
          return value;
        },
        meta: { className: 'left-align' },
      },
      {
        header: 'Time',
        accessorKey: 'time',
        cell: (cell) => moment(cell.getValue() as string).format('L LT z'),
        meta: { className: 'left-align' },
      },
      { header: 'Reporter', accessorKey: 'reporter', meta: { className: 'left-align' } },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (cell) => {
          if (cell.row.original.report.pending)
            return (
              <Button variant="rad sm low" href={`${rootPath}/report/${cell.row.original.report.reportId}`} route>
                Pending
              </Button>
            );
          return cell.getValue() as string;
        },
        meta: { className: 'left-align' },
      },
    ];

    const parseReportsToTableData = (): TableData[] => {
      const dataTable: TableData[] = [];
      reports.forEach((report) => {
        const newRow: TableData = {
          report: report,
          reportee: report.reportee.sortableName,
          reason: report.reason,
          comment: report.comment,
          time: report.createdAt,
          reporter: report.reporter ? report.reporter.sortableName : 'Peerceptiv',
          status: report.pending ? 'Pending' : report.approved ? 'Approved' : report.rejected ? 'Rejected' : 'Error',
        };

        dataTable.push(newRow);
      });
      return dataTable;
    };

    setTableData(parseReportsToTableData());
    setTableColumns(columns);
  }, [reports, rootPath]);

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      sortBy="time"
      title="Reports"
      id="reports-table"
      informOfRow={(row) => navigate(`${rootPath}/report/${row.original.report.reportId}`)}
      hideDownload={accessPermission.downloadResultPermission ? false : true}
    >
      <p>
        <b>Click on report to judge</b>
      </p>
    </Table>
  );
}

export default ReportsTable;
