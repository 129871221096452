import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Evaluation, EvaluationScoreTimeline, Rating, Rubric } from '../../types/types';
import { SAVE_DEBOUNCE_MAX_WAIT, SAVE_DEBOUNCE_WAIT } from '../../utils/constants';
import { setPageTitle } from '../../utils/functions';
import {
  getEvaluationCommentMap,
  getEvaluationRatingMap,
  getEvaluationScoreTimelines,
  getPeerEval,
  getPeerEvalRubricItems,
  updateEvaluationComments,
  updateEvaluationRatings,
} from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import EvalForm from './EvalForm';
import LiveEvalScoreboard from './LiveEvalScoreboard';

interface Props {
  canEvaluate: boolean;
  updateCb: () => void;
}

function EvalEditor({ canEvaluate, updateCb }: Props): JSX.Element {
  useEffect(() => setPageTitle('Team Member Evaluation'), []);

  const { courseId, assignmentId, peerEvalId } = useParams() as {
    courseId: string;
    assignmentId: string;
    peerEvalId: string;
  };

  const [peerEval, setPeerEval] = useState<Evaluation | null>(null);
  const [rubric, setRubric] = useState<Rubric | null>(null);
  const [scoreTimelines, setScoreTimelines] = useState<EvaluationScoreTimeline[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [commentMap, setCommentMap] = useState<{ [index: string]: string }>({});
  const [ratingMap, setRatingMap] = useState<{ [index: string]: number }>({});
  const [lastSaveTimestamp, setLastSaveTimestamp] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [finalSaveProgress, setFinalSaveProgress] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getPeerEval(peerEvalId, setPeerEval);
    getEvaluationScoreTimelines(assignmentId, peerEvalId, setScoreTimelines);
    getEvaluationCommentMap(peerEvalId, setCommentMap);
    getEvaluationRatingMap(peerEvalId, setRatingMap);
  }, [assignmentId, peerEvalId]);

  useEffect(() => {
    if (peerEval) getPeerEvalRubricItems(assignmentId, peerEval.target, setRubric);
  }, [peerEval, assignmentId]);

  const updateCommentMap = useCallback(
    (commentId: string, comment: string) =>
      setCommentMap((prevMap) => {
        const newMap = _.clone(prevMap);
        newMap[commentId] = comment;
        return newMap;
      }),
    [],
  );

  const updateRatingMap = useCallback(
    (ratingId: string, score: number) =>
      setRatingMap((prevMap) => {
        const newMap = _.clone(prevMap);
        newMap[ratingId] = score;
        return newMap;
      }),
    [],
  );

  const updateLastSaveTimestamp = () => setLastSaveTimestamp(moment().format('LTS'));

  const saveComments = useCallback(
    (map: { [index: string]: string }) => updateEvaluationComments(peerEvalId, map, updateLastSaveTimestamp),
    [peerEvalId],
  );

  const debouncedSaveComments = useMemo(
    () => _.debounce(saveComments, SAVE_DEBOUNCE_WAIT, { maxWait: SAVE_DEBOUNCE_MAX_WAIT }),
    [saveComments],
  );

  useEffect(() => {
    if (canEvaluate) debouncedSaveComments(commentMap);
  }, [commentMap, debouncedSaveComments, canEvaluate]);

  const saveRatings = useCallback(
    (map: { [index: string]: number }) => updateEvaluationRatings(peerEvalId, map, updateLastSaveTimestamp),
    [peerEvalId],
  );

  const debouncedSaveRatings = useMemo(
    () => _.debounce(saveRatings, SAVE_DEBOUNCE_WAIT, { maxWait: SAVE_DEBOUNCE_MAX_WAIT }),
    [saveRatings],
  );

  useEffect(() => {
    if (canEvaluate) debouncedSaveRatings(ratingMap);
  }, [ratingMap, debouncedSaveRatings, canEvaluate]);

  useEffect(() => {
    // Update scores timeline after a score change
    if (rubric && pageIndex < rubric.length && rubric[pageIndex].hasOwnProperty('ratingId')) {
      const rating = rubric[pageIndex] as Rating;
      const newScore = ratingMap[rating.ratingId] ?? 0;
      let updateRequired = false;
      const newScoreTimelines = scoreTimelines.map((scoreTimeline) => {
        if (scoreTimeline.peerEvalId === peerEvalId) {
          const prevDiff = scoreTimeline.scoreDiffTimeline[pageIndex];
          // If the scores for the current page differ, update the rest of the score timeline
          if (newScore !== prevDiff) {
            scoreTimeline.scoreDiffTimeline[pageIndex] = newScore;
            let prevTotal = scoreTimeline.scoreTotalTimeline[pageIndex] - prevDiff;
            for (let i = pageIndex; i < scoreTimeline.scoreTotalTimeline.length; i++) {
              scoreTimeline.scoreTotalTimeline[i] = prevTotal + scoreTimeline.scoreDiffTimeline[i];
              prevTotal = scoreTimeline.scoreTotalTimeline[i];
            }
            updateRequired = true;
          }
        }
        return scoreTimeline;
      });
      if (updateRequired) setScoreTimelines(newScoreTimelines);
    }
  }, [pageIndex, rubric, ratingMap, scoreTimelines, peerEvalId]);

  const handleSubmit = useCallback(() => {
    if (canEvaluate) {
      updateEvaluationComments(peerEvalId, commentMap, () => setFinalSaveProgress((prev) => prev + 50));
      updateEvaluationRatings(peerEvalId, ratingMap, () => setFinalSaveProgress((prev) => prev + 50));
      setLoading(true);
    } else {
      setFinalSaveProgress(100);
    }
  }, [canEvaluate, peerEvalId, commentMap, ratingMap]);

  useEffect(() => {
    updateCb();
    if (finalSaveProgress >= 100) navigate(`/course/${courseId}/assignment/${assignmentId}/evaluate/`);
  }, [assignmentId, courseId, finalSaveProgress, navigate, peerEval?.target, updateCb]);

  if (peerEval && rubric && !loading)
    return (
      <>
        <LiveEvalScoreboard
          rubric={rubric}
          currentPeerEvalId={peerEval.peerEvalId}
          title={peerEval.target}
          scoreTimelines={scoreTimelines}
          pageIndex={pageIndex}
        />

        <EvalForm
          rubric={rubric}
          commentMap={commentMap}
          ratingMap={ratingMap}
          updateCommentMap={updateCommentMap}
          updateRatingMap={updateRatingMap}
          onSubmit={handleSubmit}
          onPageIndexChange={(i) => setPageIndex(i)}
          lastSaveTimestamp={lastSaveTimestamp}
          peer={peerEval.peer}
          role={peerEval.target}
        />
      </>
    );
  return <LoadingSpinner />;
}

export default EvalEditor;
