import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { POST_SpecialPurchase, SpecialPurchase } from '../../../types/types';
import { formatDollar, formDataToObjectParsed } from '../../../utils/functions';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import Table from '../../core/display/Table/Table';
import FilterTab from '../../core/layout/FilterTab/FilterTab';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import { ColumnDef, Row } from '@tanstack/react-table';

type TableData = {
  specialPurchase: SpecialPurchase;
  specialPurchaseId: string;
  name: string;
  date: string;
  dealType: 'Seats' | 'Discount';
  deal: string;
  redeemed: string;
  expired: string;
};

type PurchaseUpdateTypes = {
  name: string | number;
  notes: string | number;
  seats?: number;
  price?: number;
  discountPercentage?: number;
};

interface Props {
  createSpecialPurchase: (specialPurchase: POST_SpecialPurchase) => void;
  deleteSpecialPurchase: (specialPurchaseId: string) => void;
  specialPurchases?: SpecialPurchase[];
  updateSpecialPurchase: (specialPurchaseId: string, specialPurchase: POST_SpecialPurchase) => void;
}

function AdminSpecialPurchases({
  createSpecialPurchase,
  deleteSpecialPurchase,
  specialPurchases,
  updateSpecialPurchase,
}: Props): JSX.Element {
  const [allTableData, setAllTableData] = useState<TableData[]>([]);
  const [redeemedTableData, setRedeemedTableData] = useState<TableData[]>([]);
  const [unredeemedTableData, setUnredeemedTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);

  const { modalDispatch } = useModalContext();

  const specialPurchasePostDataFromFormData = (data: PurchaseUpdateTypes): POST_SpecialPurchase => {
    let specialPurchase: POST_SpecialPurchase = {};
    if (data.seats && data.price) specialPurchase = { seats: data.seats, price: data.price };
    else if (data.discountPercentage) specialPurchase = { discountPercentage: data.discountPercentage };
    if (typeof data.name === 'string') specialPurchase.name = data.name;
    if (typeof data.notes === 'string') specialPurchase.notes = data.notes;
    return specialPurchase;
  };

  const openCreatePurchaseModal = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Create a Special Purchase',
          closeButton: true,
          cancelHide: true,
          buttonText: 'Create',
          form: true,
          onSubmit: (formData) => {
            const data = formDataToObjectParsed(formData) as PurchaseUpdateTypes;
            createSpecialPurchase(specialPurchasePostDataFromFormData(data));
          },
          children: <CreateSpecialPurchaseModalContent />,
        }),
      ),
    [createSpecialPurchase, modalDispatch],
  );

  const openPurchaseDetailsModal = useCallback(
    (row: Row<TableData>) =>
      modalDispatch(
        openModal({
          heading: 'Special Purchase Details',
          headingHide: true,
          closeButton: true,
          noActionButtons: true,
          form: true,
          onSubmit: (formData) => {
            const data = formDataToObjectParsed(formData) as {
              deleteCheck: 'on' | 'off';
              editCheck: 'on' | 'off';
            } & PurchaseUpdateTypes;
            if (data.deleteCheck === 'on') deleteSpecialPurchase(row.original.specialPurchaseId);
            if (data.editCheck === 'on')
              updateSpecialPurchase(row.original.specialPurchaseId, specialPurchasePostDataFromFormData(data));
          },
          children: <SpecialPurchaseModalContent specialPurchase={row.original.specialPurchase} />,
        }),
      ),

    [modalDispatch, deleteSpecialPurchase, updateSpecialPurchase],
  );

  useEffect(() => {
    const columns: ColumnDef<TableData>[] = [
      {
        header: 'Date',
        accessorKey: 'date',
        cell: (cell) => {
          moment(cell.getValue() as string).format('L LT');
        },
        meta: { className: 'left-align' },
      },
      { header: 'Name', accessorKey: 'name', meta: { className: 'left-align' } },
      { header: 'Type', accessorKey: 'dealType' },
      { header: 'Deal', accessorKey: 'deal' },
      { header: 'Redeemed?', accessorKey: 'redeemed' },
      { header: 'Expired?', accessorKey: 'expired' },
    ];

    const parsePurchasesToTableData = (purchaseList?: SpecialPurchase[]): TableData[] => {
      const dataTable: TableData[] = [];
      purchaseList?.forEach((specialPurchase) => {
        const newRow: TableData = {
          specialPurchase: specialPurchase,
          specialPurchaseId: specialPurchase.specialPurchaseId,
          name: specialPurchase.name,
          date: specialPurchase.createdAt,
          dealType: specialPurchase.discountPercentage > 0 ? 'Discount' : 'Seats',
          deal:
            specialPurchase.discountPercentage > 0
              ? `-${specialPurchase.discountPercentage}%`
              : `${specialPurchase.seats} for ${formatDollar(specialPurchase.price)}`,
          redeemed: specialPurchase.redeemed ? 'Yes' : 'No',
          expired: specialPurchase.expired ? 'Yes' : 'No',
        };

        dataTable.push(newRow);
      });
      return dataTable;
    };

    setAllTableData(parsePurchasesToTableData(specialPurchases));
    setRedeemedTableData(
      parsePurchasesToTableData(specialPurchases?.filter((specialPurchase) => specialPurchase.redeemed)),
    );
    setUnredeemedTableData(
      parsePurchasesToTableData(specialPurchases?.filter((specialPurchase) => !specialPurchase.redeemed)),
    );
    setTableColumns(columns);
  }, [specialPurchases]);

  const tableData = filterList.includes('Redeemed')
    ? redeemedTableData
    : filterList.includes('Not Redeemed')
    ? unredeemedTableData
    : allTableData;

  if (specialPurchases) {
    return (
      <>
        <div className="ctrls-row">
          <Button onClick={openCreatePurchaseModal}>Create Special Purchase</Button>
        </div>
        <div className="table-wrapper panel-sm">
          <Table
            columns={tableColumns}
            data={tableData}
            sortBy="date"
            title="Purchase Log"
            id="purchase-log-table"
            noWrapper
            informOfRow={openPurchaseDetailsModal}
          >
            <FilterTab label="Show:" setFilterList={setFilterList}>
              <FilterTab.Button id="btn-all" type="radio" name="specialpurchase-filters" defaultChecked={true}>
                All
              </FilterTab.Button>
              <FilterTab.Button id="btn-redeemed" type="radio" name="specialpurchase-filters">
                Redeemed
              </FilterTab.Button>
              <FilterTab.Button id="btn-unredeemed" type="radio" name="specialpurchase-filters">
                Not Redeemed
              </FilterTab.Button>
            </FilterTab>
          </Table>
        </div>
      </>
    );
  }
  return <LoadingSpinner />;
}

function SpecialPurchaseModalContent({ specialPurchase }: { specialPurchase: SpecialPurchase }): JSX.Element {
  const [deleteCheck, setDeleteCheck] = useState(false);
  const [editCheck, setEditCheck] = useState(false);

  return (
    <div id="admin-purchase-modal-content">
      {!editCheck ? (
        <table className="nice-table">
          <tbody>
            <tr>
              <th>Name</th>
              <td>{specialPurchase.name}</td>
            </tr>
            <tr>
              <th>Code</th>
              <td>
                <pre>{specialPurchase.specialPurchaseCode}</pre>
              </td>
            </tr>
            <tr>
              <th>Date</th>
              <td>{moment(specialPurchase.createdAt).format('L LT')}</td>
            </tr>{' '}
            <tr>
              <th>Type</th>
              <td> {specialPurchase.discountPercentage > 0 ? 'Discount' : 'Seats'}</td>
            </tr>
            <tr>
              <th>Deal</th>
              <td>
                {' '}
                {specialPurchase.discountPercentage > 0
                  ? `-${specialPurchase.discountPercentage}%`
                  : `${specialPurchase.seats} for ${formatDollar(specialPurchase.price)}`}
              </td>
            </tr>
            <tr>
              <th>Redeemed?</th>
              <td>
                <Icon code={specialPurchase.redeemed ? 'done' : 'close'} />
              </td>
            </tr>
            <tr>
              <th>Expired?</th>
              <td>
                <Icon code={specialPurchase.expired ? 'done' : 'close'} />
              </td>
            </tr>
            <tr>
              <th>Notes:</th>
              <td>{specialPurchase.notes ?? 'None'}</td>
            </tr>
          </tbody>
        </table>
      ) : null}

      <div
        id="edit-check-wrapper"
        className={`ctrl-wrapper rad-radio-btn ${editCheck || deleteCheck ? 'sr-only' : ''}`}
      >
        <input
          id="edit-check"
          name="editCheck"
          type="checkbox"
          checked={editCheck}
          onChange={(e) => setEditCheck(e.target.checked)}
        />
        <label htmlFor="edit-check">Edit Purchase</label>
      </div>
      <div
        id="delete-check-wrapper"
        className={`ctrl-wrapper rad-radio-btn ${deleteCheck || editCheck ? 'sr-only' : ''}`}
      >
        <input
          id="delete-check"
          name="deleteCheck"
          type="checkbox"
          checked={deleteCheck}
          onChange={(e) => setDeleteCheck(e.target.checked)}
        />
        <label htmlFor="delete-check">Delete Purchase</label>
      </div>

      {editCheck === true ? (
        <div id="update-special-purchase-modal">
          <div className="input-wrapper">
            <label className="sr-only" htmlFor="name">
              Special Purchase Name:
            </label>
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Name (Optional)"
              defaultValue={specialPurchase.name}
            />
          </div>

          {specialPurchase.discountPercentage > 0 ? (
            <div className="input-wrapper">
              <label htmlFor="discountPercentage">Discount (%):</label>
              <input
                id="discountPercentage"
                name="discountPercentage"
                type="number"
                placeholder="%"
                min={1}
                max={100}
                defaultValue={specialPurchase.discountPercentage}
                required
              />
            </div>
          ) : (
            <>
              <div className="input-wrapper">
                <label htmlFor="seats">Seats:</label>
                <input
                  id="seats"
                  name="seats"
                  type="number"
                  placeholder="#"
                  min={1}
                  defaultValue={specialPurchase.seats}
                  required
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="seats">Price (¢):</label>
                <input
                  id="price"
                  name="price"
                  type="number"
                  placeholder="¢"
                  min={0}
                  defaultValue={specialPurchase.price}
                  required
                />
              </div>
            </>
          )}

          <div className="input-wrapper">
            <label className="sr-only" htmlFor="notes">
              Notes:
            </label>
            <textarea id="notes" name="notes" placeholder="Notes (Optional)" defaultValue={specialPurchase.notes} />
          </div>

          <div className="confirm-wrapper">
            <div className="ctrls">
              <Button variant="rad" type="submit">
                Save Changes
              </Button>
              <Button variant="rad alt" type="button" onClick={() => setEditCheck(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      ) : null}

      {deleteCheck === true ? (
        <div className="confirm-wrapper">
          <p>
            <b>Are you sure you want to delete this special purchase?</b>
          </p>
          <div className="ctrls">
            <Button variant="rad" type="submit">
              Delete
            </Button>
            <Button variant="rad alt" type="button" onClick={() => setDeleteCheck(false)}>
              Cancel
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function CreateSpecialPurchaseModalContent(): JSX.Element {
  const [filterList, setFilterList] = useState<string[]>([]);

  return (
    <div id="create-purchase-modal-content">
      <div className="input-wrapper">
        <label className="sr-only" htmlFor="name">
          Special Purchase Name:
        </label>
        <input id="name" name="name" type="text" placeholder="Name (Optional)" />
      </div>

      <FilterTab label="Type:" setFilterList={setFilterList}>
        <FilterTab.Button id="btn-seats" type="radio" name="specialpurchase-filters" defaultChecked={true}>
          Seats
        </FilterTab.Button>
        <FilterTab.Button id="btn-discount" type="radio" name="specialpurchase-filters">
          Discount
        </FilterTab.Button>
      </FilterTab>
      {filterList.includes('Seats') ? (
        <>
          <div className="input-wrapper">
            <label htmlFor="seats">Seats:</label>
            <input id="seats" name="seats" type="number" placeholder="#" min={1} required />
          </div>
          <div className="input-wrapper">
            <label htmlFor="seats">Price (¢):</label>
            <input id="price" name="price" type="number" placeholder="¢" min={0} required />
          </div>
        </>
      ) : null}
      {filterList.includes('Discount') ? (
        <div className="input-wrapper">
          <label htmlFor="discountPercentage">Discount (%):</label>
          <input
            id="discountPercentage"
            name="discountPercentage"
            type="number"
            placeholder="%"
            min={1}
            max={100}
            required
          />
        </div>
      ) : null}

      <div className="input-wrapper">
        <label className="sr-only" htmlFor="notes">
          Notes:
        </label>
        <textarea id="notes" name="notes" placeholder="Notes (Optional)" />
      </div>
    </div>
  );
}

export default AdminSpecialPurchases;
