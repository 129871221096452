import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectAssignment } from '../../store/selectors';
import { SubmissionInfo } from '../../types/types';
import { getInstructorSubmissionInfos } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';

function InstructorSubmissionMenu(): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const basePath = `/course/${courseId}/assignment/${assignmentId}`;

  const assignment = useSelector(selectAssignment);
  const [submissionInfos, setSubmissionInfos] = useState<SubmissionInfo[] | null>(null);

  useEffect(() => getInstructorSubmissionInfos(assignmentId, setSubmissionInfos), [assignmentId]);

  if (assignment && submissionInfos) {
    const published = assignment.progressStats?.published ?? false;
    return (
      <div id="instructor-submission-menu" className="page">
        <div id="menu-wrapper" className="panel-sm">
          <h2 className="title">Instructor Submissions</h2>
          <p>
            <b>Minimum required submissions: {assignment.numberOfReviewers}</b>
          </p>
          <p>
            <b>Current instructor submissions: {submissionInfos.length}</b>
          </p>
          {submissionInfos.length > 0 ? (
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Submission Time</th>
                    <th>Type</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {submissionInfos.map((submissionInfo) => (
                    <tr key={submissionInfo.submissionId}>
                      <td>{moment(submissionInfo.createdAt).format('lll')}</td>
                      <td>{submissionInfo.submissionType}</td>
                      <td>
                        <Button
                          variant="alt rad low"
                          href={`${basePath}/submission/${submissionInfo.submissionId}`}
                          route
                        >
                          View Submission
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>There are no instructor submissions yet.</p>
          )}

          <Button variant="rad" href={!published ? `${basePath}/submission` : undefined} route disabled={published}>
            New Submission
          </Button>
        </div>
      </div>
    );
  }
  return <LoadingSpinner />;
}

export default InstructorSubmissionMenu;
