import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../store';
import { Assignment, ProgressStats, AssignmentProgress, Course } from '../../../types/types';
import { getStudentAssignmentProgress } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import StudentAssignmentTutorial from '../../tutorial/StudentAssignmentTutorial';
import AssignmentDetailsCard from '../AssignmentDetailsCard';
import PhaseNowCard from '../PhaseNowCard';
import SubmitCard from './SubmitCard';
import ReviewCard from './ReviewCard';
import FeedbackCard from './FeedbackCard';
import EvaluateCard from './EvaluateCard';
import ReflectionCard from './ReflectionCard';
import GroupFormationCard from './GroupFormationCard';

interface Props {
  assignment: Assignment;
  course: Course;
  timeRemaining: string;
}

function StudentDashboardOld({ assignment, timeRemaining }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const [currentTaskElement, setCurrentTaskElement] = useState<JSX.Element>(<></>);
  const [userProgress, setAssignmentProgress] = useState<AssignmentProgress | null>(null);
  const user = useSelector((state: RootState) => state.user);
  const rootPath = `/course/${courseId}/assignment/${assignmentId}`;

  useEffect(() => {
    if (assignment && assignment.assignmentId)
      getStudentAssignmentProgress(assignment.assignmentId, setAssignmentProgress);
  }, [assignment, assignmentId]);
  const status = userProgress?.status;

  useEffect(() => {
    if (assignment && userProgress) {
      const task = assignmentCurrentStudentTask(assignment, userProgress);

      if (task.href)
        setCurrentTaskElement(
          <Button id="next-task-btn" className="pointer-animation-right" href={task.href} route>
            {task.text}
          </Button>,
        );
      else setCurrentTaskElement(<p>{task.text}</p>);
    }
  }, [assignment, userProgress]);

  return (
    <div id="student-dashboard" className="page">
      <div className="dashboard">
        <div className="overview">
          <div className="card-wrapper">
            <AssignmentDetailsCard assignment={assignment}>
              <div className="curr-task-wrapper">
                {assignment && assignment.progressStats && status ? (
                  <>
                    {assignment.status === 'ACTIVE' ? (
                      <div className="to-do-wrapper">
                        {status?.hasSubmitted ||
                        assignment.progressStats?.submissionPhase ||
                        status.canLateSubmit ||
                        assignment.reviewingWithoutSubmission ||
                        assignment.instructorGradedOnly ||
                        assignment.peerEvaluationOnly ||
                        assignment.instructorUpload ||
                        assignment.groupFormationOnly ? (
                          <>
                            <div className="next-task-label">Next task:</div>
                            {currentTaskElement}
                          </>
                        ) : (
                          <>
                            <Icon code="error" ariaHidden />
                            <span>Missed submission! Cannot continue assignment</span>
                          </>
                        )}
                      </div>
                    ) : null}
                    {assignment.status === 'COMPLETE' ? (
                      <div className="to-do-wrapper">
                        <span>Results Available:</span>
                        {currentTaskElement}
                      </div>
                    ) : null}
                    {assignment.asyncEndDeadline !== null ? (
                      <div>
                        <b>Due: </b>
                        {moment(assignment.asyncEndDeadline).format('l [@] LT')}
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </AssignmentDetailsCard>
            <PhaseNowCard assignment={assignment} timeRemaining={timeRemaining} />
          </div>
        </div>
        <div className="dashboard-cards-container">
          {assignment && assignment.progressStats && userProgress && status ? (
            <>
              {status.showGroupFormation ? (
                <GroupFormationCard
                  assignment={assignment}
                  userProgress={userProgress}
                  progressStats={assignment.progressStats}
                  rootPath={rootPath}
                />
              ) : null}
              {status.showSubmission ? (
                <SubmitCard
                  assignment={assignment}
                  userProgress={userProgress}
                  progressStats={assignment.progressStats}
                  rootPath={rootPath}
                />
              ) : null}
              {status.showReviewing ? (
                <ReviewCard
                  assignment={assignment}
                  userProgress={userProgress}
                  numberOfReviewers={assignment.numberOfReviewers}
                  progressStats={assignment.progressStats}
                  rootPath={rootPath}
                />
              ) : null}
              {status.showFeedback ? (
                <FeedbackCard
                  assignment={assignment}
                  userProgress={userProgress}
                  progressStats={assignment.progressStats}
                  rootPath={rootPath}
                />
              ) : null}
              {status.showEvaluations ? (
                <EvaluateCard
                  assignment={assignment}
                  userProgress={userProgress}
                  progressStats={assignment.progressStats}
                  rootPath={rootPath}
                />
              ) : null}
              {status.showReflection ? (
                <ReflectionCard
                  assignment={assignment}
                  userProgress={userProgress}
                  progressStats={assignment.progressStats}
                  rootPath={rootPath}
                  userId={user.userId}
                />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
      {user.actingAs ? null : <StudentAssignmentTutorial />}
    </div>
  );
}

export interface PhaseCardProps {
  assignment: Assignment;
  progressStats: ProgressStats;
  rootPath: string;
  userProgress: AssignmentProgress;
}

export type TaskStatus = {
  text: string;
  href?: string;
};

export const assignmentCurrentStudentTask = (assignment: Assignment, progress: AssignmentProgress): TaskStatus => {
  const { courseId, assignmentId, asyncEnabled, progressStats: stats, groupsEnabled } = assignment;
  const { status } = progress;
  const rootPath = `/course/${courseId}/assignment/${assignmentId}`;

  if (groupsEnabled && !status.inGroup) {
    if (assignment.groupFormationEnabled) {
      if (progress.groupFormationResponseComplete) return { text: 'Survey Complete - Awaiting Group Placement' };
      else return { text: 'Group Survey', href: `${rootPath}/groups/formation` };
    }
    return { text: 'Create/Join Group', href: `${rootPath}/groups` };
  }

  if (status.hasResult) {
    return { text: 'View Results', href: `${rootPath}/results` };
  }

  if (asyncEnabled ? status.submissionPriority : stats?.submissionPhase) {
    if (status.canSubmit && !status.hasSubmitted) {
      return { text: 'Submit', href: `${rootPath}/submission` };
    } else {
      return { text: 'Submission Complete — Awaiting Review Phase' };
    }
  }

  if (asyncEnabled ? status.reviewPriority : stats?.reviewPhase) {
    if (!status.hasSubmitted && !assignment.instructorUpload) {
      if (status.canLateSubmit) {
        return { text: 'Submit Late', href: `${rootPath}/submission` };
      } else if (!assignment.reviewingWithoutSubmission) {
        return { text: 'Missed Submission — Cannot Review', href: '' };
      }
    }

    const { completedReviews, incompleteReviews } = progress;
    if (status.completedAllReviews && !status.canEvaluate) {
      return { text: 'Reviews Complete — Awaiting Feedback Phase' };
    } else if (incompleteReviews && incompleteReviews.length > 0) {
      return { text: 'Continue Review', href: `${rootPath}/review/${incompleteReviews[0].reviewId}` };
    } else if (
      completedReviews &&
      incompleteReviews &&
      assignment.numberOfReviewers > completedReviews.length + incompleteReviews.length
    ) {
      if (asyncEnabled && progress.status.waitingForReport === true)
        return { text: 'Waiting for Report Action by Instructor', href: '' };

      let reviewNumber = 1;
      if (progress.completedReviews) reviewNumber += progress.completedReviews.length;
      const submissionLock =
        assignment.reviewingWithoutSubmission && !progress.status.hasSubmitted && reviewNumber === 1;
      const submissionLockParam = submissionLock ? '?submissionLock=true' : '';
      return {
        text: `Start Review ${reviewNumber}`,
        href: `${rootPath}/review/new${submissionLockParam}`,
      };
    }

    if (assignment.selfReviewEnabled && progress.submissionInfo) {
      const { selfReview } = progress;
      if (selfReview) {
        return {
          text: `${!selfReview.complete ? 'Continue' : 'Edit'} Self-Review`,
          href: `${rootPath}/review/${selfReview.reviewId}`,
        };
      } else {
        return {
          text: 'Start Self-Review',
          href: `${rootPath}/review/new?selfReview=true`,
        };
      }
    }
  }

  if (asyncEnabled ? status.feedbackPriority : stats?.feedbackPhase) {
    if (status.completedAllFeedback && !status.canEvaluate) {
      return {
        text: `Feedback Complete — ${assignment.reflectionEnabled ? 'Awaiting Reflect Phase' : 'Awaiting Result'}`,
      };
    }

    const { reviewsRequiringFeedback } = progress;
    if (status.canCompleteFeedback && reviewsRequiringFeedback && reviewsRequiringFeedback.length > 0) {
      return {
        text: 'Start Feedback',
        href: `${rootPath}/review/${reviewsRequiringFeedback[0].reviewId}/feedback?feedbackCompleted=${progress.feedbackCompleted}`,
      };
    }
  }

  if (asyncEnabled ? status.peerEvalPriority : stats?.evalPhase) {
    if (status.completedAllEvaluations) {
      return { text: 'Evaluations Complete' };
    }

    if (status.canEvaluate) {
      return {
        text: 'Evaluate',
        href: `${rootPath}/evaluate`,
      };
    }
  }

  if (assignment.reflectionEnabled) {
    if (status.canReflect) {
      if (asyncEnabled ? status.reflectionPriority : stats?.reflectionPhase) {
        if (progress.reflection) {
          if (progress.reflection.complete) {
            return {
              text: 'Reflection Complete - Awaiting Results',
              href: ``,
            };
          } else {
            return {
              text: 'Continue Reflection',
              href: `${rootPath}/reflection/${progress.reflection.reflectionId}`,
            };
          }
        } else {
          return {
            text: 'Start Reflection',
            href: `${rootPath}/reflection/new`,
          };
        }
      }
    } else {
      if (asyncEnabled && progress.reflection && progress.reflection.complete) {
        return {
          text: 'Reflection Complete - Awaiting Results',
          href: ``,
        };
      } else {
        return {
          text: 'Reflect task unavailable',
          href: ``,
        };
      }
    }
  }

  if (asyncEnabled && status.waitingForResults) {
    return { text: 'All Tasks Complete — Awaiting Results', href: '' };
  }

  if (asyncEnabled && status.waitingForReviews) {
    return { text: 'Waiting For Peer Reviews', href: '' };
  }

  if (asyncEnabled && status.waitingForSubmissions) {
    return { text: 'Waiting For Peer Submissions', href: '' };
  }

  return { text: 'Waiting for Available Tasks', href: '' };
};

export default StudentDashboardOld;
